import React, { useEffect, useState } from 'react';

import Routes from './routes/Routes';

// import { configureFakeBackend } from './helpers';

// Themes
// For Default import Theme.scss
import './assets/scss/Theme.scss';
import { useAppDispatch, useAppSelector } from './redux/store';
import { loginUser } from './redux/actions';
import { api, setAuthorization } from './helpers/api/apiCore';

// For Dark import Theme-Dark.scss
// import './assets/scss/Theme-Dark.scss';

// configure fake backend
// configureFakeBackend();

const App = () => {
    return (
        <Routes />
    );
};

export default App;
