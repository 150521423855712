import { Vehicle } from "../../types";

export enum VEHICLE_ACTIONS {
  VEHICLE_LIST = "VEHICLE_LIST",
  VEHICLE_AVAILABLE_PER_STATION_LIST = "VEHICLE_AVAILABLE_PER_STATION_LIST",
  VEHICLE_ERROR = "VEHICLE_ERROR",
  VEHICLE_OPTIONS_MODAL = "VEHICLE_OPTIONS_MODAL",
  VEHICLE_UPDATE_MODAL = "VEHICLE_UPDATE_MODAL",
  VEHICLE_CREATE_MODAL = "VEHICLE_CREATE_MODAL",
  VEHICLE_IS_DELETED_LOADING = "VEHICLE_IS_DELETED_LOADING",
  VEHICLE_PRICES_MODAL = "VEHICLE_PRICES_MODAL",
  VEHICLE_GEAR_BOXES = "VEHICLE_GEAR_BOXES",
  VEHICLE_LIST_IMMATRICULATION = "VEHICLE_LIST_IMMATRICULATION",
  VEHICLE_LOADING = "VEHICLE_LOADING",
}

export interface VehicleState {
  vehicles: Vehicle[];
  availableVehiclesPerStation: Vehicle[],
  listGearBoxes: string[];
  listImmatriculations: string[];
  showCreateModal: boolean;
  showPriceModal: boolean;
  showUpdateModal: boolean;
  isLoading: boolean;
  isDeletedLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: VehicleState = {
  vehicles: [],
  availableVehiclesPerStation: [],
  listGearBoxes: [],
  listImmatriculations: [],
  showCreateModal: false,
  showUpdateModal: false,
  showPriceModal: false,
  isLoading: false,
  isDeletedLoading: false,
  error: null,
};

export interface VehicleActionType {
  type: keyof typeof VEHICLE_ACTIONS;
  payload: any;
}

const VehicleReducer = (
  state: VehicleState = initialState,
  action: VehicleActionType
): VehicleState => {
  switch (action.type) {
    case VEHICLE_ACTIONS.VEHICLE_LIST:
      return {
        ...state,
        vehicles: action.payload,
        isLoading: false,
      };

    case VEHICLE_ACTIONS.VEHICLE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case VEHICLE_ACTIONS.VEHICLE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case VEHICLE_ACTIONS.VEHICLE_CREATE_MODAL:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    case VEHICLE_ACTIONS.VEHICLE_PRICES_MODAL:
      return {
        ...state,
        showPriceModal: action.payload,
      };

    case VEHICLE_ACTIONS.VEHICLE_UPDATE_MODAL:
      return {
        ...state,
        showUpdateModal: action.payload,
      };

    case VEHICLE_ACTIONS.VEHICLE_IS_DELETED_LOADING:
      return {
        ...state,
        isDeletedLoading: action.payload,
      };

      case VEHICLE_ACTIONS.VEHICLE_AVAILABLE_PER_STATION_LIST:
        return {
          ...state,
          availableVehiclesPerStation: action.payload
        };
  
      case VEHICLE_ACTIONS.VEHICLE_GEAR_BOXES:
        return {
          ...state,
          listGearBoxes: action.payload
        };
  
      case VEHICLE_ACTIONS.VEHICLE_LIST_IMMATRICULATION:
        return {
          ...state,
          listImmatriculations: action.payload
        };
      
    default:
      return {
        ...state,
      };
  }
};

export default VehicleReducer;
