import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
import { setCancelReservationModal, setOpenFactureDommageEditionModal, setValidateReservationModal } from '../../redux/etats/actions';
import { EtatsState } from '../../redux/etats/reducers';
import { deleteEtatThunk, loadDommagesThunk, loadEtatsThunk } from '../../redux/etats/thunk';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Etat, Reservation, RESERVATIONS_STATUS } from '../../types';
import { formatDateTimeLocal } from '../../utils/date';
import { formatCurrency } from "../../utils/number";
import { etaStatusClass, EtatStatusLabel } from '../../utils/reservation';
import CancelDialog from './CancelDialog';
import DetailsDialog from './DetailsDialog';
import ValidateDialog from './ValidateDialog';
import FactureDommage from './FactureDommage';
import { useParams } from 'react-router-dom';

const sizePerPageList = [
  {
    text: '5',
    value: 5,
  },
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  }
];

const Dommages = () => {
  const dispatch = useAppDispatch();
  const { etats, showCancelModal, showValidateModal, showNewAndEditFactureDommageModal } = useAppSelector(({ Etats }) => Etats) as EtatsState;
  const [etat, setEtat] = useState<Etat | null>(null);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);

  const handleValidateShow = useCallback(() => dispatch(setValidateReservationModal(true)), [dispatch]);
  const handleValidateClose = useCallback(() => dispatch(setValidateReservationModal(false)), [dispatch]);
  const handleCancelShow = useCallback(() => dispatch(setCancelReservationModal(true)), [dispatch]);
  const handleCancelClose = useCallback(() => dispatch(setCancelReservationModal(false)), [dispatch]);
  const handleDetailShow = useCallback(() => setShowDetailsDialog(true), [setShowDetailsDialog]);
  const handleDetailClose = useCallback(() => setShowDetailsDialog(false), [setShowDetailsDialog]);

  const handleOpenCloseFactureDommageEdition = useCallback((payload: boolean) =>
    () => dispatch(setOpenFactureDommageEditionModal(payload)), [dispatch])

 
    const { reservationId } = useParams<{ reservationId: string }>();

  useEffect(() => {
    if (etats.length === 0) {
      dispatch(loadDommagesThunk(reservationId));
    }
  }, [dispatch]);


  // let etats0 = etats?.find({reservationId: reservationId});
  
  const columns = useMemo(() => [
    {
      Header: 'Utilisateur',
      accessor: '',
      Cell: ({ row }: { row: any, [x: string]: any }) => {

        return (
          <div className="d-flex gap-2">
            <div
              className="rounded-circle"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                overflow: 'hidden'
              }}>
              {row?.original?.user?.photoUrl ? (
                <img style={{
                  objectFit: 'cover',
                  backgroundPosition: 'center',
                  backgroundSize: "cover",
                  width: "100%",
                  height: "100%",
                }} src={row?.original?.user?.photoUrl} alt={row?.original?.user?.name} />
              ) : (
                <div
                  className="bg-primary border-2 border-secondary d-flex align-items-center justify-content-center"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                >
                  <h5 className="fw-bold text-white">
                    {row?.original?.user?.name[0] + "." + row?.original?.user?.firstName[0]}
                  </h5>
                </div>
              )}
            </div>
            <div className="d-flex flex-column gap-1">
              <div className="d-flex gap-1">
                <span className="fw-bold">{row?.original?.user?.name}</span>
                <span className="fw-bold">{row?.original?.user?.firstName}</span>
              </div>

              <span>{row?.original?.user?.email}</span>
            </div>
          </div>
        )
      },
    },
    {
      accessor: '',
      Header: "Voiture",
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <div className="flex">
            <div
              className="border p-1"
              style={{
                width: "100px",
                height: "100px",
                overflow: 'hidden'
              }}
            >
              <img style={{
                objectFit: 'contain',
                backgroundPosition: 'center',
                backgroundSize: "contain",
                width: "100%",
                height: "100%",
              }}
                src={row?.original?.vehicleInstance?.vehicle?.absolutePhotoUrl}
                alt={row?.original?.vehicleInstance?.vehicle?.name}
              />
            </div>
            <div className="d-flex flex-column gap-2">
              <h1 className="h5 mb-0 pb-0">{row.original?.vehicleInstance?.name}</h1>
            </div>
          </div>
        );
      },
      disableGlobalFilter: true,
    },
    {
      Header: "Période",
      accessor: 'type',
      sort: true,
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span
            className={`badge py-1 ${etaStatusClass(row?.original?.type)}`}
          >
            {EtatStatusLabel(row?.original?.type)}
          </span>
        )
      },
    },
    {
      Header: "Description",
      accessor: 'description',
      sort: true,
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span className="text-uppercase fw-bold">
            {row?.original?.description}
          </span>
        )
      },
    },
    {
      Header: 'Créé le',
      accessor: 'createdAt',
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span>{formatDateTimeLocal(row?.original?.createdAt)}</span>
        )
      },
      sort: true,
    },
    {
      id: "Actions",
      accessor: '',
      Header: "Action",
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        const etat = row.original as Etat;

        return (
          <div className="d-flex align-items-center gap-2">
              <button
                className="btn btn-danger rounded-3 d-flex gap-2 fw-bold text-uppercase"
                // disabled={}
                onClick={() => {
                  if (window.confirm("êtes-vous sûr ?")) {
                    dispatch(deleteEtatThunk(row.original._id));
                  }
                }}
              >
                <>
                <span className="mr-2">
                  <i className="bi bi-trash-fill"></i>
                </span>
                </>
              </button>

              <button
                className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
                onClick={() => {
                  setEtat(row.original);
                  handleDetailShow();
                }}
              >
              <span className="mr-2">
                <i className="bi bi-eye-fill"></i>
              </span>
            </button>
            <button
                className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
                onClick={() => {
                  setEtat(row.original);
                  handleOpenCloseFactureDommageEdition(true)()
                }}
              >
              <span className="mr-2">
              <i className="bi bi-file-text-fill"></i>
              </span>
              Facturer dommage
            </button>
          </div>
        );
      },
      disableGlobalFilter: true,
    },
  ], [etat]);
  
  return (
    <>
      <PageTitle
        breadCrumbItems={[]}
        title={"Dommages"}
      />
      {/* <div className="d-flex justify-content-end mb-3">
        <button
          className="btn btn-primary rounded-3 d-flex gap-2 fw-bold text-uppercase"
          onClick={(e) => {}}
        >
          <span className="mr-2">
            <i className="bi bi-plus"></i>
          </span>
          Ajouter un dommage 
        </button>
      </div> */}
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <h4 className="header-title">Liste</h4>
              <Table
                columns={columns}
                data={etats}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Create modal */}
      {etat ? (
        <>
          <DetailsDialog
            handleClose={handleDetailClose}
            etat={etat}
            show={showDetailsDialog}
          />

          <FactureDommage
            handleClose={handleOpenCloseFactureDommageEdition(false)}
            etat={etat}
            show={showNewAndEditFactureDommageModal}
          />
        </>
      ) : (<></>)}
    </>
  );
};

export default Dommages;
