import { Revision } from "../../types";
import { InterventionActionType, INTERVENTIONS_ACTIONS } from "./reducers";

/**
 * Load Station action builder.
 * @param Station
 * @returns
 */
export const setStations = (station: Revision[]): InterventionActionType => ({
  type: INTERVENTIONS_ACTIONS.INTERVENTIONS_LIST,
  payload: station,
});

/**
 * Error action builder.
 * @param Station
 * @returns
 */
 export const setStationError = (error: any): InterventionActionType => ({
  type: INTERVENTIONS_ACTIONS.INTERVENTIONS_ERROR,
  payload: error,
});

/**
 * Station loading action builder.
 * @param Station
 * @returns
 */
 export const setStationLoading = (isLoading: boolean): InterventionActionType => ({
  type: INTERVENTIONS_ACTIONS.INTERVENTIONS_LOADING,
  payload: isLoading,
});

/***
 * Set MCreate modal state
 */
export const setCreateinterventionModal = (show: boolean) : InterventionActionType => ({
  type: INTERVENTIONS_ACTIONS.INTERVENTIONS_CREATE_MODAL,
  payload: show,
})

/**
 * set update Station modal state.
 * @param show 
 * @returns 
 */
export const setUpdateinterventionModal = (show: boolean) : InterventionActionType => ({
  type: INTERVENTIONS_ACTIONS.INTERVENTIONS_UPDATE_MODAL,
  payload: show,
});

export const setDeleteLoading = (
  loading: boolean
): InterventionActionType => ({
  type: INTERVENTIONS_ACTIONS.INTERVENTION_CANCEL_LOADING,
  payload: loading,
});


