import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

// components
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import ReservationRequest from '../pages/reservationsRequests';
import ReservationsHistory from '../pages/reservationHistory';
import ReservationOne from '../pages/reservation';
import FactureDateRangePicker from '../pages/factures';
import Contraventions from '../pages/contravention';
import Dommages from '../pages/dommages';
import ThePlanning from '../pages/thePlanning';

// lazy load all the views
// AUTH COMPONENTS
const Login = React.lazy(() => import('../pages/auth/Login'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
const LockScreen = React.lazy(() => import('../pages/auth/LockScreen'));
// AUTH COMPONENTS

// PRIVATE PAGES COMPONENTS
const AppDashboard = React.lazy(() => import('../pages/dashboard/App'));
const VehicleType = React.lazy(() => import("../pages/vehicleTypes"));
const Vehicles = React.lazy(() => import("../pages/vehicles"));
const VehiclesInstances = React.lazy(() => import("../pages/vehicleInstances"));
const Stations = React.lazy(() => import("../pages/stations"));
const Users = React.lazy(() => import("../pages/users"));
const KmForfaits = React.lazy(() => import("../pages/kmForfaits"));
const InsuranceForfaits = React.lazy(() => import("../pages/insuranceForfaits"));
const Options = React.lazy(() => import("../pages/options"));
const Reservations = React.lazy(() => import("../pages/reservations"));
const Interventions = React.lazy(() => import("../pages/interventions"));
const Etats = React.lazy(() => import("../pages/etat"));
// PRIVATE PAGES COMPONENTS

const Error404 = React.lazy(() => import('../pages/error/Error404'));
const Error500 = React.lazy(() => import('../pages/error/Error500'));

export interface RoutesProps {
    path: RouteProps['path'];
    name?: string;
    component?: RouteProps['component'];
    route?: any;
    exact?: RouteProps['exact'];
    icon?: string;
    header?: string;
    roles?: string[];
    children?: RoutesProps[];
}

// root routes
const rootRoute: RoutesProps = {
    path: '/',
    exact: true,
    component: () => <Root />,
    route: Route,
};

// auth
const authRoutes: RoutesProps[] = [
    {
        path: '/auth/login',
        name: 'Login',
        component: Login,
        route: Route,
    },
    {
        path: '/auth/register',
        name: 'Register',
        component: Register,
        route: Route,
    },
    {
        path: '/auth/confirm',
        name: 'Confirm',
        component: Confirm,
        route: Route,
    },
    {
        path: '/auth/lock-screen',
        name: 'Lock Screen',
        component: LockScreen,
        route: Route,
    },
    {
        path: '/auth/logout',
        name: 'Logout',
        component: Logout,
        route: Route,
    },
];

// dashboards
const dashboardRoutes: RoutesProps = {
    path: '/',
    name: 'Dashboards',
    icon: 'airplay',
    header: 'Navigation',
    children: [
        {
            path: "/dashboard",
            component: AppDashboard,
            name: "App",
            route: PrivateRoute,
        },
        {
            path: '/modeles',
            name: 'Vehicules',
            component: Vehicles,
            route: PrivateRoute,
        },
        {
            path: '/vehicules',
            name: 'Vehicules-instance',
            component: VehiclesInstances,
            route: PrivateRoute,
        },
        {
            path: '/interventions',
            name: 'Interventions',
            component: Interventions,
            route: PrivateRoute
        },
        {
            path: '/types-vehicules',
            name: 'VehicleType',
            component: VehicleType,
            route: PrivateRoute,
        },
        {
            path: '/stations',
            name: 'stations',
            component: Stations,
            route: PrivateRoute,
        },
        {
            path: '/utilisateurs',
            name: 'Users',
            component: Users,
            route: PrivateRoute,
        },
        {
            path: '/pack-kilometriques',
            name: 'kmPacks',
            component: KmForfaits,
            route: PrivateRoute,
        },
        {
            path: '/assurances',
            name: 'InsuranceForfaits',
            component: InsuranceForfaits,
            route: PrivateRoute,
        },
        {
            path: '/options',
            name: 'Option',
            component: Options,
            route: PrivateRoute,
        },
        {
            path: '/reservations',
            name: 'Reservations',
            component: Reservations,
            route: PrivateRoute,
        },
        {
            path: '/reservations-requests',
            name: 'ReservationRequest',
            component: ReservationRequest,
            route: PrivateRoute,
        },
        {
            path: '/planning',
            name: 'ThePlanning',
            component: ThePlanning,
            route: PrivateRoute,
        },
        {
            path: '/reservationHistory/:reservationId',
            name: 'ReservationsHistory',
            component: ReservationsHistory,
            route: PrivateRoute,
        },
        {
            path: '/reservation/:reservationId',
            name: 'Reservation',
            component: ReservationOne,
            route: PrivateRoute,
        },
        {
            path: '/facture',
            name: 'FactureDateRangePicker',
            component: FactureDateRangePicker,
            route: PrivateRoute,
        },
        {
            path: '/contravention/:reservationId',
            name: 'Contraventions',
            component: Contraventions,
            route: PrivateRoute,
        },
        {
            path: '/dommages/:reservationId',
            name: 'Dommages',
            component: Dommages,
            route: PrivateRoute,
        },
        {
            path: '/etats',
            name: 'Etats',
            component: Etats,
            route: PrivateRoute,
        }
    ],
};

// public routes
const otherPublicRoutes: RoutesProps[] = [
    {
        path: '/error-404',
        name: 'Error - 404',
        component: Error404,
        route: Route,
    },
    {
        path: '/error-500',
        name: 'Error - 500',
        component: Error500,
        route: Route,
    },
];

// flatten the list of all nested routes
const flattenRoutes = (routes: RoutesProps[]) => {
    let flatRoutes: RoutesProps[] = [];

    routes = routes || [];
    routes.forEach((item: RoutesProps) => {
        flatRoutes.push(item);

        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};

// All routes
const authProtectedRoutes = [rootRoute, dashboardRoutes];
const publicRoutes = [...authRoutes, ...otherPublicRoutes];

const authProtectedFlattenRoutes = flattenRoutes([...authProtectedRoutes]);
const publicProtectedFlattenRoutes = flattenRoutes([...publicRoutes]);
export { publicRoutes, authProtectedRoutes, authProtectedFlattenRoutes, publicProtectedFlattenRoutes };
