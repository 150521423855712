import { InsuranceForfait } from "../../types";

export enum INSURANCE_FORFAIT_ACTIONS {
  INSURANCE_FORFAIT_LIST = "INSURANCE_FORFAIT_LIST",
  INSURANCE_FORFAIT_ERROR = "INSURANCE_FORFAIT_ERROR",
  INSURANCE_FORFAIT_UPDATE_MODAL = "INSURANCE_FORFAIT_UPDATE_MODAL",
  INSURANCE_FORFAIT_CREATE_MODAL = "INSURANCE_FORFAIT_CREATE_MODAL",
  INSURANCE_FORFAIT_LOADING = "INSURANCE_FORFAIT_LOADING",
}

export interface InsuranceForfaitsState {
  insuranceForfais: InsuranceForfait[];
  showCreateModal: boolean;
  showUpdateModal: boolean;
  isLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: InsuranceForfaitsState = {
  insuranceForfais: [],
  showCreateModal: false,
  showUpdateModal: false,
  isLoading: false,
  error: null,
};

export interface InsuranceForfaitActionType {
  type: keyof typeof INSURANCE_FORFAIT_ACTIONS;
  payload: any;
}

const insuranceForfaitReducer = (
  state: InsuranceForfaitsState = initialState,
  action: InsuranceForfaitActionType
): InsuranceForfaitsState => {
  switch (action.type) {
    case INSURANCE_FORFAIT_ACTIONS.INSURANCE_FORFAIT_LIST:
      return {
        ...state,
        insuranceForfais: action.payload,
        isLoading: false,
      };

    case INSURANCE_FORFAIT_ACTIONS.INSURANCE_FORFAIT_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case INSURANCE_FORFAIT_ACTIONS.INSURANCE_FORFAIT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case INSURANCE_FORFAIT_ACTIONS.INSURANCE_FORFAIT_CREATE_MODAL:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    case INSURANCE_FORFAIT_ACTIONS.INSURANCE_FORFAIT_UPDATE_MODAL:
      return {
        ...state,
        showUpdateModal: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default insuranceForfaitReducer;
