import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import { RootState, useAppDispatch, useAppSelector } from '../redux/store';

import { APICore, setAuthorization } from '../helpers/api/apiCore';
import { AuthState } from '../redux/auth/reducers';
import { loginUser } from '../redux/actions';

interface PrivateRouteProps {
    component: React.FunctionComponent<RouteProps>;
    roles?: string;
}

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ component: Component, roles, ...rest }: PrivateRouteProps) => {
    const api = new APICore();
    // const token = useSelector<RootState>((state) => state.Auth.token as string);

    const [loaded, setLoaded] = useState(true);
    const { user, token } = useAppSelector(({ Auth }) => Auth) as AuthState;
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (user !== null) {
            setLoaded(true);
            return;
        }

        (async () => {
            try {
                const authData = await localStorage.getItem("helpen_ur") || JSON.stringify(null);

                const { user: UserLocal, token } = JSON.parse(authData) || {};

                if (UserLocal === undefined || token  === undefined || !api.isUserAuthenticated(token || "")) {
                    await localStorage.removeItem("helpen_ur");
                    if (window.location.href !== "/auth/login") {
                        window.location.href = "/auth/login";
                    }
                    return;
                }

                dispatch(loginUser({ user: UserLocal, token }));
                setAuthorization(token);
            } catch (error) {
                await localStorage.removeItem("helpen_ur");
                if (window.location.href !== "/auth/login") {
                    window.location.href = "/auth/login"
                }
                console.log({ error });
            }
        })()
        setLoaded(true);
    }, [setLoaded, dispatch, user]);

    if (!loaded) {
        return <div>Chargement...</div>;
    }

    return (
        <Route
            {...rest}
            render={(props: RouteComponentProps) => {
                if (api.isUserAuthenticated(token as string) === false) {
                    // not logged in so redirect to login page with the return url
                    return (
                        <Redirect
                            to={{
                                pathname: '/auth/login',
                                state: { from: props['location'] },
                            }}
                        />
                    );
                }

                // const loggedInUser = api.getLoggedInUser();

                // // check if route is restricted by role
                // if (roles && roles.indexOf(loggedInUser.role) === -1) {
                //     // role not authorised so redirect to home page
                //     return <Redirect to={{ pathname: '/' }} />;
                // }
                // authorised so return component
                return <Component {...props} />;
            }}
        />
    );
};

export default PrivateRoute;
