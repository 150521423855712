import { APICore } from "../../helpers/api/apiCore";
import { setCreateOptionModal, setDeleteLoading, setOptionError, setOptionLoading, setOptions, setOptionsBasedStation } from "./actions";


export interface Optiondata {
  id?: string;
  name: string;
  price: number;
  stationId: string | null;
  requireUser: boolean;
}

/**
 * Load insurance forfaits thunk.
 *
 * @param param0
 * @returns
 */
export const loadOptionsThunk = () => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setOptionLoading(true));

      const resp = await api.get("/options");
      
      if (resp.data?.status === "OK") {
        console.log(resp.data.result);
        dispatch(setOptions(resp.data.result));
        return;
      }

      dispatch(setOptionLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setOptionError(err));
    }
  };
};


export const loadOptionsBasedStationThunk = (station: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      // dispatch(setOptionLoading(true));

      const resp = await api.get(`/options?stationId=${station}`);

      if (resp.data?.status === "OK") {
        dispatch(setOptionsBasedStation(resp.data.result));
        return;
      }

      // dispatch(setOptionLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }
      // dispatch(setOptionError(err));
    }
  };
};


/**
 * Create insurance forfait thunk.
 *
 * @param param0
 * @returns
 */
export const createOptionThunk = (data: Optiondata) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      const url = data.id ? `/admin/options/${data.id}` : "/admin/options";
      const resp = data.id ? await api.update(url, data) : await api.create(url, data);;

      if (resp.data) {
        dispatch(loadOptionsThunk());
        dispatch(setCreateOptionModal(false));
      }
    } catch (error: any) {
      console.log({ error });

      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      dispatch(setOptionError(err));
    }
  };
};

/**
 * Valdate a new reservation thunk.
 *
 * @returns
 */
export const deleteOptionThunk = (id: string) => {
  return async (dispatch: any, getState: any, api: APICore) => {
    try {
      dispatch(setDeleteLoading(true));
      const resp = await api.delete(`/admin/options/${id}`);

      if (resp.data?.status === "OK") {
        dispatch(setCreateOptionModal(false));
        dispatch(loadOptionsThunk());
      }

      dispatch(setDeleteLoading(false));
    } catch (error: any) {
      let err = error;
      if (error.response) {
        err = error.response.data.error;
      }

      if (typeof err === "string") {
        alert(err);
      }

      dispatch(setDeleteLoading(false));
    }
  };
};
