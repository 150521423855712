import { Etat } from "../../types";

export enum ETAT_ACTIONS {
  ETAT_LIST = "ETAT_LIST",
  ETAT_ERROR = "ETAT_ERROR",
  ETAT_CANCEL_MODAL = "ETAT_CANCEL_MODAL",
  ETAT_VALIDATE_MODAL = "ETAT_VALIDATE_MODAL",
  ETAT_VALIDATE_STATUS = "ETAT_VALIDATE_STATUS",
  ETAT_CANCEL_STATUS = "ETAT_CANCEL_STATUS",
  ETAT_LOADING = "ETAT_LOADING",
  ETAT_VALIDATE_LOADING = "ETAT_VALIDATE_LOADING",
  ETAT_CANCEL_LOADING = "ETAT_CANCEL_LOADING",
  RESERVATION_FACTURE_DOMMAGE_OPEN_CLOSE_NEW_EDIT_MODAL = "RESERVATION_FACTURE_DOMMAGE_OPEN_CLOSE_NEW_EDIT_MODAL",
}


export interface EtatsState {
  etats: Etat[];
  showValidateModal: boolean;
  showCancelModal: boolean;
  isValidateLoading: boolean;
  isCancelLoading: boolean;
  isLoading: boolean;
  error: { [x: string]: string } | string | null;
  showNewAndEditFactureDommageModal: boolean;
}

const initialState: EtatsState = {
  etats: [],
  showValidateModal: false,
  showCancelModal: false,
  isLoading: false,
  isCancelLoading: false,
  isValidateLoading: false,
  error: null,
  showNewAndEditFactureDommageModal: false,
};

export interface EtatActionType {
  type: keyof typeof ETAT_ACTIONS;
  payload: any;
}

const etatsReducer = (
  state: EtatsState = initialState,
  action: EtatActionType
): EtatsState => {
  switch (action.type) {
    case ETAT_ACTIONS.ETAT_LIST:
      return {
        ...state,
        etats: action.payload,
        isLoading: false,
      };

    case ETAT_ACTIONS.ETAT_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case ETAT_ACTIONS.ETAT_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ETAT_ACTIONS.ETAT_VALIDATE_MODAL:
      return {
        ...state,
        showValidateModal: action.payload,
      };

    case ETAT_ACTIONS.ETAT_CANCEL_MODAL:
      return {
        ...state,
        showCancelModal: action.payload,
      };

    case ETAT_ACTIONS.ETAT_VALIDATE_LOADING:
      return {
        ...state,
        isValidateLoading: action.payload,
      };

    case ETAT_ACTIONS.ETAT_CANCEL_LOADING:
      return {
        ...state,
        isCancelLoading: action.payload,
      };

      
      case ETAT_ACTIONS.RESERVATION_FACTURE_DOMMAGE_OPEN_CLOSE_NEW_EDIT_MODAL:
      return {
        ...state,
        showNewAndEditFactureDommageModal: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default etatsReducer;
