import { Modal } from "react-bootstrap";
import { useAppDispatch } from "../../redux/store";
import { Reservation } from "../../types";
import ReservationCard from "./ReservationCard";

interface Props {
  show: boolean;
  handleClose: () => void;
  reservation: Reservation;
}

export default function DetailsDialog({
  handleClose,
  show,
  reservation,
}: Props) {
  const dispatch = useAppDispatch();

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h4">
          Details de la reservation Nº {reservation.reservationNumber}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReservationCard reservation={reservation} />
      </Modal.Body>
    </Modal>
  )
}