import { Vehicle } from "../../types";

export enum VEHICLE_TYPES_ACTIONS {
  VEHICLE_TYPES_LIST = "VEHICLE_TYPES_LIST",
  VEHICLE_TYPES_ERROR = "VEHICLE_TYPES_ERROR",
  VEHICLE_TYPES_OPTIONS_MODAL = "VEHICLE_TYPES_OPTIONS_MODAL",
  VEHICLE_TYPES_UPDATE_MODAL = "VEHICLE_TYPES_UPDATE_MODAL",
  VEHICLE_TYPES_CREATE_MODAL = "VEHICLE_TYPES_CREATE_MODAL",
  VEHICLE_TYPES_LOADING = "VEHICLE_TYPES_LOADING",
}

export interface VehicleTypestate {
  vehicleTypes: Vehicle[];
  showCreateModal: boolean;
  showUpdateModal: boolean;
  isLoading: boolean;
  error: { [x: string]: string } | string | null;
}

const initialState: VehicleTypestate = {
  vehicleTypes: [],
  showCreateModal: false,
  showUpdateModal: false,
  isLoading: false,
  error: null,
};

export interface VehicleTypeActionType {
  type: keyof typeof VEHICLE_TYPES_ACTIONS;
  payload: any;
}

const vehicleTypeReducer = (
  state: VehicleTypestate = initialState,
  action: VehicleTypeActionType
): VehicleTypestate => {
  switch (action.type) {
    case VEHICLE_TYPES_ACTIONS.VEHICLE_TYPES_LIST:
      return {
        ...state,
        vehicleTypes: action.payload,
        isLoading: false,
      };

    case VEHICLE_TYPES_ACTIONS.VEHICLE_TYPES_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case VEHICLE_TYPES_ACTIONS.VEHICLE_TYPES_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case VEHICLE_TYPES_ACTIONS.VEHICLE_TYPES_CREATE_MODAL:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    case VEHICLE_TYPES_ACTIONS.VEHICLE_TYPES_UPDATE_MODAL:
      return {
        ...state,
        showUpdateModal: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default vehicleTypeReducer;
