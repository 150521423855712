import { Button, Col, Modal, Row } from "react-bootstrap";
import { useAppDispatch } from "../../redux/store";
import { Etat, Reservation } from "../../types";
import ReservationCard from "./ReservationCard";
import { FormInput } from "../../components";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from "react-hook-form";
import { useCallback, useEffect } from "react";
import { createFactureDomageThunk } from "../../redux/reservations/thunk";

interface Props {
  show: boolean;
  handleClose: () => void;
  etat: Etat;
}

interface IFormValue {
  libele: string;
  montant: number;
  dommageDeclare: "oui" | "non";
  constat: "oui" | "non";
  isCautionDebite: "oui" | "non";
}

export default function FactureDommage({
  handleClose,
  show,
  etat,
}: Props) {
  const dispatch = useAppDispatch();

  const reservationId = etat?.reservationId;
  const etatId = etat?._id;

  
  const schemaResolver = yupResolver(
    yup.object().shape({
      libele: yup.string().required("Veuillez saisir un libellé"),
      montant: yup.number().required("Veuillez saisir un montant"),
      dommageDeclare: yup.string().oneOf(["oui", "non"]).required("Veuillez sélectionner une réponse"),
      constat: yup.string().oneOf(["oui", "non"]).required("Veuillez sélectionner une réponse"),
      isCautionDebite: yup.string().oneOf(["oui", "non"]).required("Veuillez sélectionner une réponse"),
    })
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    getValues,
  } = useForm<IFormValue>({
    resolver: schemaResolver,
    defaultValues: {},
  });
  
  const onSubmit = useCallback(
    (data) => {
      console.log("azerty")
      const contraventionData = { ...data, reservationId, etatId };
      dispatch(createFactureDomageThunk(contraventionData));
      handleClose(); // Ferme le modal après la soumission du formulaire
      data.reservationId = reservationId;
      data.etatId= etatId;
    },
    [dispatch, reservationId]
  );

  // const onSubmit = useCallback((data) => {
  //   console.log("azerty", data);
  //   // ...
  // }, [dispatch, reservationId]);

  useEffect(() => {
    reset({});
    if(!dommageDeclare){
      setValue('dommageDeclare', 'oui')
    }
    if(!constat){
      setValue('constat', 'oui')
    }
    if(!isCautionDebite){
      setValue('isCautionDebite', 'oui')
    }
    if ( isCautionDebite === 'oui' || !montant) {
      setValue('montant', 0);
    } else {
      // setValue('montant', /* une autre valeur par défaut si nécessaire */);
    }
  }, [reset, reservationId, setValue]);

  const { dommageDeclare } = watch();
  
  const { constat } = watch();

  const { isCautionDebite } = watch();

  const { montant } = watch();

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h4">
          Déclarer un dommage
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <form onSubmit={handleSubmit(onSubmit) } className="authentication-form" noValidate>
        <Row>
        <label className="form-label col-3">
                Le locataire a t-il déclaré le dommage ? 
            </label>
            <Col className="col-2">
              <label className="form-label">
                <FormInput
                  type="radio"
                  name="dommageDeclare"
                  value="oui"
                  label="Oui"
                  register={register}
                  containerClass="mb-3"
                  defaultChecked 
                />
              </label>
              </Col>
              <Col className="col-2">
              <label className="form-label">
                <FormInput
                  type="radio"
                  name="dommageDeclare"
                  value="non"
                  label="Non"
                  register={register}
                  containerClass="mb-3"
                />
              </label>
            </Col>
            {dommageDeclare !== 'non' && (
              <span className="col-5" style={{color:'red'}}></span>
            )}
            {dommageDeclare === 'non' && (
              <span className="col-5" style={{color:'red'}}>90€ de frais seront facturés.</span>
            )}

<label className="form-label col-3">
          Le locataire a t-il fourni un constat ?
            </label>
            <Col className="col-2">
              <label className="form-label">
                <FormInput
                  type="radio"
                  name="constat"
                  value="oui"
                  label="Oui"
                  register={register}
                  containerClass="mb-3"
                  defaultChecked 
                />
              </label>
              </Col>
              <Col className="col-2">
              <label className="form-label">
                <FormInput
                  type="radio"
                  name="constat"
                  value="non"
                  label="Non"
                  register={register}
                  containerClass="mb-3"
                />
              </label>
            </Col>
            {constat !== 'oui' && (
              <>
              <span className="col-5" style={{color:'red'}}></span>
              <label className="form-label col-3">
                Bloquer la caution ?
            </label>
            <Col className="col-2">
              <label className="form-label">
                <FormInput
                  type="radio"
                  name="isCautionDebite"
                  value="oui"
                  label="Oui"
                  register={register}
                  containerClass="mb-3"
                  defaultChecked 
                />
              </label>
              </Col>
              <Col className="col-2">
              <label className="form-label">
                <FormInput
                  type="radio"
                  name="isCautionDebite"
                  value="non"
                  label="Non"
                  register={register}
                  containerClass="mb-3"
                />
              </label>
            </Col>
            {constat === 'non' && isCautionDebite === 'oui' && (
              <>
              <span className="col-5" style={{color:'red'}}>100% la caution sera bloqué pendant 30 jours calendaires.</span>
              </>
            )}
            {constat === 'non' && isCautionDebite === 'non' && (
              <>
              <span className="col-5" style={{color:'red'}}>Facturer le dommage. Des frais de 30€ seront facturés.</span>
              <Col className="col-12">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="number"
                name="montant"
                min={0}
                required
                defaultValue={0}
                placeholder="Montant du dommage"
                label={'Montant du dommage'}
                containerClass={'mb-3'}
              />
            </Col>
            </>
            )}

              </>
            )}
            {constat === 'oui' && (
              <span className="col-5" style={{color:'red'}}>100% la caution sera débitée.</span>
            )}

           
            <Col className="col-12">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="text"
                name="libele"
                required
                placeholder="Libellé du dommage"
                label={'Libellé du dommage'}
                containerClass={'mb-3'}
              />
            </Col>
            
          </Row>


                <div className="mb-3 text-center d-flex flex-column gap-2">
                  <Button type="submit" variant="primary" disabled={isSubmitting}>
                    Ajouter
                  </Button>
                  <Button variant="light" onClick={handleClose}>
                    Annuler
                  </Button>{' '}
                </div>
      </form>
      </Modal.Body>
    </Modal>
  )
}
