import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { APICore } from '../helpers/api/apiCore';
import { RootState } from '../redux/store';

const Root = () => {
    const api = new APICore();
    const token = useSelector<RootState>((state) => state.Auth.token as string);

    const getRootUrl = () => {
        let url: string = 'auth/login';

        // check if user logged in or not and return url accordingly
        if (api.isUserAuthenticated(token as string) === false) {
            url = 'auth/login';
        } else {
            url = 'dashboard';
        }
        return url;
    };

    const url = getRootUrl();

    return <Redirect to={`/${url}`} />;
};

export default Root;
