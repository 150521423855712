import { Station } from "../../types";
import { STATIONS_ACTIONS, StationActionType } from "./reducers";

/**
 * Load Station action builder.
 * @param Station
 * @returns
 */
export const setStations = (station: Station[]): StationActionType => ({
  type: STATIONS_ACTIONS.STATIONS_LIST,
  payload: station,
});

/**
 * Error action builder.
 * @param Station
 * @returns
 */
 export const setStationError = (error: any): StationActionType => ({
  type: STATIONS_ACTIONS.STATIONS_ERROR,
  payload: error,
});

/**
 * Station loading action builder.
 * @param Station
 * @returns
 */
 export const setStationLoading = (isLoading: boolean): StationActionType => ({
  type: STATIONS_ACTIONS.STATIONS_LOADING,
  payload: isLoading,
});

/***
 * Set MCreate modal state
 */
export const setCreateStationModal = (show: boolean) : StationActionType => ({
  type: STATIONS_ACTIONS.STATIONS_CREATE_MODAL,
  payload: show,
})

/**
 * set update Station modal state.
 * @param show 
 * @returns 
 */
export const setUpdateStationModal = (show: boolean) : StationActionType => ({
  type: STATIONS_ACTIONS.STATIONS_UPDATE_MODAL,
  payload: show,
})


