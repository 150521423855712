import { IOption } from "../../types";
import { OPTION_ACTIONS, OptionActionType } from "./reducers";

/**
 * Load Station action builder.
 * @param Station
 * @returns
 */
export const setOptions = (type: IOption[]): OptionActionType => ({
  type: OPTION_ACTIONS.OPTION_LIST,
  payload: type,
});

export const setOptionsBasedStation = (options: IOption[]) :OptionActionType => ({
  type: OPTION_ACTIONS.OPTION_LIST_STATION_BASED,
  payload: options,
})

/**
 * Error action builder.
 * @param Station
 * @returns
 */
 export const setOptionError = (error: any): OptionActionType => ({
  type: OPTION_ACTIONS.OPTION_ERROR,
  payload: error,
});

/**
 * Station loading action builder.
 * @param Station
 * @returns
 */
 export const setOptionLoading = (isLoading: boolean): OptionActionType => ({
  type: OPTION_ACTIONS.OPTION_LOADING,
  payload: isLoading,
});

/***
 * Set MCreate modal state
 */
export const setCreateOptionModal = (show: boolean) : OptionActionType => ({
  type: OPTION_ACTIONS.OPTION_CREATE_MODAL,
  payload: show,
})

/**
 * set update Station modal state.
 * @param show 
 * @returns 
 */
export const setUpdateOptionModal = (show: boolean) : OptionActionType => ({
  type: OPTION_ACTIONS.OPTION_UPDATE_MODAL,
  payload: show,
})

export const setDeleteLoading = (value: boolean) : OptionActionType => ({
  type: OPTION_ACTIONS.OPTION_DELETED_LOADING,
  payload: value,
})
