import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { FormInput } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { VehicleState } from "../../redux/vehicle/reducers";
import { createVehicleThunk, loadGearBoxes, loadVehicleAvailblePerStation, loadVehicleImmatriculation, updateVehicleThunk } from "../../redux/vehicle/thunk";
import { VehicleTypestate } from "../../redux/vehicleTypes/reducers";
import { Reservation, Vehicle, VehicleInstance, VehicleType, } from "../../types";
import { StationState } from '../../redux/stations/reducers';
import { loadStationsThunk } from '../../redux/stations/thunk';
import { OptionsState } from '../../redux/options/reducers';
import { loadOptionsBasedStationThunk } from '../../redux/options/thunk';
import { createReservationThunk, reservationEtats, updateReservation } from '../../redux/reservations/thunk';

interface Props {
  show: boolean;
  handleClose: () => void;
  vehicle?: Vehicle | null;
  reservation?: Reservation;
}

interface IFormValue {
  startDateTime: string;
  endDateTime: string;
  station: string;
  vehicle: string;
  gearbox: string | null; // boites de vitesse => "auto" | "manuel" | "semi-auto"
  immatriculation: string;
  optionId: string;
  optionAmount_1: string;
  optionAmount_2: string;
  optionAmount_3: string;
  optionAmount_4: string;
  optionId_1: string;
  optionId_2: string;
  optionId_3: string;
  optionId_4: string;
  optionDesc_1: string;
  optionDesc_2: string;
  optionDesc_3: string;
  optionDesc_4: string;
  optionQty_1: number;
  optionQty_2: number;
  optionQty_3: number;
  optionQty_4: number;
  clientEmail: string;
  reservationExtern: string;
  source: string;
  reservationStatus: string;
  reservationPrice: number;
  reservationPaidAmount: number;
  reservationAmountToBePaid: number;
  reservationTotalToBePaid: number;
}

export default function EditFormDialog({
  handleClose,
  show,
  vehicle = null,
  reservation
}: Props) {
  const dispatch = useAppDispatch();
  const { vehicleTypes } = useAppSelector(({ vehicleTypes }) => vehicleTypes) as VehicleTypestate;
  const { isLoading, availableVehiclesPerStation, listGearBoxes, listImmatriculations } = useAppSelector(({ vehicles }) => vehicles) as VehicleState;
  const { stations } = useAppSelector(({ stations }) => stations as StationState)
  const { optionsBasedStation } = useAppSelector(({ Options }) => Options as OptionsState)

  const [reservationNetToPay, setReservationNetToPay] = useState(0)
  const [reservationTotalToBePaid, setReservationTotalToBePaid] = useState(0)
  const [reservationAmountToBePaid, setReservationAmountToBePaid] = useState(0)

  const schemaResolver = yupResolver(
    yup.object().shape({
      // name: yup.string().typeError("Veuillez saisir un texte").required("requis"),
      // dailyPrice: yup.number().typeError("Veuillez saisir un nombre").required("Veuillez saisir un prix journalier"),
      // vehicleType: yup.string().oneOf(vehicleTypes.map((v) => v._id), "Veuillez choisir un type"),
      // brand: yup.string().typeError("Veuillez saisir un texte").required("requis"),
      // doorsCount: yup.number().typeError("Veuillez saisir un nombre").required("requis"),
      // seatsCount: yup.number().typeError("Veuillez saisir un nombre").required("requis"),
      // kilometreage: yup.number().typeError("Veuillez saisir un nombre").required("requis"),
      // gearbox: yup.string().oneOf(["manuelle", "auto"], "Requis"),
      // fuelTypes: yup.string().oneOf(["essence", "diesel", "hybride", "electrique"], "Requis"),
    })
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    getValues,
  } = useForm<IFormValue>({
    resolver: schemaResolver,
    defaultValues: reservation !== null ? {
      startDateTime: reservation?.startAt as any as string,
      clientEmail: reservation?.clientEmail as string,
      reservationExtern: reservation?.reservationExtern as string
    } : {

      optionQty_1: 0,
      optionQty_2: 0,
      optionQty_3: 0,
      optionQty_4: 0,
    }
  });

  const onSubmit = useCallback((data) => {

    const dataToSendToWire = ({
      ...data,
      loadReservationList: false,
      ...(reservation ? { reservationExtern: reservation.reservationExtern } : {}),
      clientEmail: reservation?.clientEmail,
      reservationTotalToBePaid: +reservationNetToPay + Number(data.reservationPrice),
      reservationAmountToBePaid: (+reservationNetToPay + Number(data.reservationPrice)) - Number(data.reservationPaidAmount),
      status: "WAITING_PAYMENT",
      source: "Rentile",
    })

    // if (reservation) {
    //   // Update reservation
    //   dispatch(updateReservation(reservation._id, {
    //     startDateTime: dataToSendToWire.startDateTime || reservation.startAt,
    //     endDateTime: dataToSendToWire.endDateTime || reservation.endAt,
    //     station: dataToSendToWire.station || reservation.vehicleInstance?.stationId,
    //     vehicle: dataToSendToWire.vehicle || reservation?.vehicleInstance?.vehicleId,
    //     clientEmail: dataToSendToWire.clientEmail || reservation?.clientEmail,
    //     reservationExtern: dataToSendToWire.reservationExtern || reservation?.reservationExtern,
    //     gearbox: dataToSendToWire.gearbox || reservation?.vehicleInstance?.vehicle.gearbox,
    //     immatriculation: dataToSendToWire.immatriculation || reservation?.vehicleInstance?.immatriculation,
    //     source: dataToSendToWire.source || reservation?.source,
    //     reservationStatus: dataToSendToWire.reservationStatus || reservation?.reservationStatus,
    //     reservationPrice: dataToSendToWire.reservationPrice || reservation?.reservationPrice,
    //     reservationPaidAmount: dataToSendToWire.reservationPaidAmount || reservation?.reservationPaidAmount,
    //     reservationTotalToBePaid: dataToSendToWire.reservationTotalToBePaid || reservation?.reservationTotalToBePaid,
    //     optionId_1: dataToSendToWire.optionId_1 || reservation?.optionId_1,
    //     optionId_2: dataToSendToWire.optionId_2 || reservation?.optionId_2,
    //     optionId_3: dataToSendToWire.optionId_3 || reservation?.optionId_3,
    //     optionId_4: dataToSendToWire.optionId_4 || reservation?.optionId_4,
    //     optionDesc_4: dataToSendToWire.optionDesc_4 || reservation?.optionDesc_4,
    //     optionQty_1: dataToSendToWire.optionQty_1 || reservation?.optionQty_1,
    //     optionQty_2: dataToSendToWire.optionQty_2 || reservation?.optionQty_2,
    //     optionQty_3: dataToSendToWire.optionQty_3 || reservation?.optionQty_3,
    //     optionQty_4: dataToSendToWire.optionQty_4 || reservation?.optionQty_4,
    //   }))
    // } else {
      dispatch(createReservationThunk(dataToSendToWire, reset))
    // }

  }, [dispatch, reservation, reservationNetToPay, reset]);

  useEffect(() => {
    if (!stations.length) {
      setReservationNetToPay(0)
      dispatch(loadStationsThunk())
    }
  }, [dispatch, stations])

  useEffect(() => {
    reset({
      startDateTime: reservation?.startAt as any as string,
      endDateTime: reservation?.endAt as any as string,
      vehicle: reservation?.vehicleInstance?.vehicleId,
      clientEmail: reservation?.clientEmail,
      reservationExtern: reservation?.reservationExtern,
      station: reservation?.vehicleInstance?.stationId,
      gearbox: reservation?.vehicleInstance?.vehicle.gearbox,
      immatriculation: reservation?.vehicleInstance?.immatriculation,
      source: reservation?.source,
      reservationStatus: reservation?.reservationStatus,
      reservationPrice: reservation?.reservationPrice,
      reservationPaidAmount: reservation?.reservationPaidAmount,
      reservationTotalToBePaid: reservation?.reservationTotalToBePaid,
      optionId_1: reservation?.optionId_1,
      optionId_2: reservation?.optionId_2,
      optionId_3: reservation?.optionId_3,
      optionId_4: reservation?.optionId_4,
      optionDesc_4: reservation?.optionDesc_4,
      optionQty_1: reservation?.optionQty_1,
      optionQty_2: reservation?.optionQty_2,
      optionQty_3: reservation?.optionQty_3,
      optionQty_4: reservation?.optionQty_4,
    });
  }, [reset, reservation]);

  useEffect(() => {
    const zero = 0;
    const subscribe = watch((data, { name, type }) => {
      if (data.station && data.startDateTime && data.endDateTime) {
        console.log("a1")
        setReservationNetToPay(zero)
        dispatch(loadVehicleAvailblePerStation({ station: data.station as string, from: (data.startDateTime as string), to: (data.endDateTime as string) }))
        console.log("a2")
      }

      if (data.station) {
        console.log("a3")
        // setValue('optionQty_1', 0);
        // setValue('optionQty_2', 0);
        // setValue('optionQty_3', 0);
        // setValue('optionQty_4', 0);
        // setValue('optionAmount_1', '0');
        // setValue('optionAmount_2', '0');
        // setValue('optionAmount_3', '0');
        // setValue('optionAmount_4', '0');
        dispatch(loadOptionsBasedStationThunk(data.station as string))
        console.log("a4")
        setReservationNetToPay(zero)
        console.log(reservationNetToPay)
      }

      if ((data.vehicle) && !data.gearbox) {
        console.log("a5")
        console.log(availableVehiclesPerStation)
        console.log(data.vehicle)
        const  vehicle  = (availableVehiclesPerStation).find(vehicleInstance => (vehicleInstance?._id === (data.vehicle))) as any 
        console.log("a6")
        console.log(vehicle)
        dispatch(loadGearBoxes({ name: vehicle?.name, brand: vehicle?.brand, station: data?.station }))
        console.log("a6")
        console.log(vehicle)
      }

      if (data.gearbox && data.vehicle && !data.immatriculation) {
        console.log("a7")
        const  vehicle  = (availableVehiclesPerStation).find(vehicleInstance => (vehicleInstance?._id === (data.vehicle))) as any 
        dispatch(loadVehicleImmatriculation({ name: vehicle?.name, brand: vehicle?.brand, gearbox: data.gearbox, station: data?.station, from: data?.startDateTime, to: data?.endDateTime  }))
        console.log("a8")
      }

      if (((data.optionQty_1 && data.optionId_1) || (data.optionQty_2 && data.optionId_2) || (data.optionQty_3 && data.optionId_3) || (data.optionQty_4 && data.optionId_4))) {
        setReservationNetToPay(0)
        console.log("a9")
        const amountTable = []

        if (data.optionQty_1 && data.optionId_1) {
          const option = optionsBasedStation.find(opt => opt._id === data.optionId_1 as string)
          amountTable.push({ qty: (data.optionQty_1 || 0), price: (option?.price || 0) })
          const prix1 = option?.price || 0;
          console.log("option1")
          console.log(prix1);
          console.log(data.optionQty_1 )
          setReservationNetToPay(reservationNetToPay+prix1)
        }
        if (data.optionQty_2 && data.optionId_2) {
          const option = optionsBasedStation.find(opt => opt._id === data.optionId_2 as string)
          amountTable.push({ qty: (data.optionQty_2 || 0), price: (option?.price || 0) })
          const prix2 = option?.price || 0;
          setReservationNetToPay(reservationNetToPay+prix2)
        }
        if (data.optionQty_3 && data.optionId_3) {
          const option = optionsBasedStation.find(opt => opt._id === data.optionId_3 as string)
          amountTable.push({ qty: (data.optionQty_3 || 0), price: (option?.price || 0) })
          const prix3 = option?.price || 0;
          setReservationNetToPay(reservationNetToPay+prix3)
        }
        if (data.optionQty_4 && data.optionId_4) {
          const option = optionsBasedStation.find(opt => opt._id === data.optionId_4 as string)
          amountTable.push({ qty: (data.optionQty_4 || 0), price: (option?.price || 0) })
          const prix4 = option?.price || 0;
          setReservationNetToPay(reservationNetToPay+prix4)
        }

        // setReservationNetToPay(0)
        // console.log("a10")
        // const totalAmount = (() => {
        //   const total = amountTable
        //     .reduce((sum, value) => sum += (value.qty * value.price), 0)
        //   return total
        // })() as number
        // setReservationNetToPay(totalAmount)
        // console.log("a11")
        // console.log("YAYA")
        // console.log(reservationNetToPay)

        const totalAmount = (() => {
          const total = amountTable.reduce((sum, value) => sum += (value.qty * value.price), 0);
          return total;
        })() as number;
      
        setReservationNetToPay(totalAmount);
        console.log("a11");
        console.log("YAYA");
        console.log(reservationNetToPay);
      }

      if (data.reservationPrice && reservationNetToPay) {
        // console.log(reservationNetToPay)
        // setReservationTotalToBePaid(+reservationNetToPay + +(data.reservationPrice || 0))
        // setValue('reservationTotalToBePaid', +reservationNetToPay + +(data.reservationPrice || 0))
        setReservationTotalToBePaid(+reservationNetToPay + +(data.reservationPrice || 0));
        setReservationAmountToBePaid(+reservationTotalToBePaid - +(data.reservationPaidAmount || 0));

      }

      if (data.reservationPaidAmount) {
        setReservationTotalToBePaid(+reservationNetToPay + +(data.reservationPrice || 0));
        setReservationAmountToBePaid(+reservationTotalToBePaid - +(data.reservationPaidAmount || 0))
        // setValue('reservationAmountToBePaid', +reservationTotalToBePaid - +(data.reservationPaidAmount || 0))
      }
    })

    return () => subscribe.unsubscribe()
  }, [availableVehiclesPerStation, dispatch, optionsBasedStation, optionsBasedStation.length, reservationNetToPay, reservationTotalToBePaid, setValue, watch])

    // Add this useEffect block to update reservationNetToPay when the station changes
    useEffect(() => {
      // Reset reservationNetToPay to 0 when the station changes
      setReservationNetToPay(0);
      
        setValue('optionQty_1', 0);
        setValue('optionQty_2', 0);
        setValue('optionQty_3', 0);
        setValue('optionQty_4', 0);
        setValue('optionAmount_1', '0');
        setValue('optionAmount_2', '0');
        setValue('optionAmount_3', '0');
        setValue('optionAmount_4', '0');

        setValue('gearbox', '')
        setValue('immatriculation', '')
        // const subscribe = watch((data, { name, type }) => {
        //   if (data.station && data.startDateTime && data.endDateTime) {
        //     console.log("a1")
        //     dispatch(loadVehicleAvailblePerStation({ station: data.station as string, from: (data.startDateTime as string), to: (data.endDateTime as string) }))
        //     console.log("a2")
        //   }
        // })

        // dispatch(loadVehicleAvailblePerStation({ station: station as string, from: (data.startDateTime as string), to: (data.endDateTime as string) }))
  // Dispatch loadVehicleAvailblePerStation if needed
  // dispatch(loadVehicleAvailblePerStation({
  //   station: watch('station') as string,
  //   from: watch('startDateTime') as string,
  //   to: watch('endDateTime') as string
  // }));
        
      console.log("ah oui!!")

      // You may perform additional logic here based on the new station value
    }, [watch('station')]); // Trigger the effect when the station value changes
    
    useEffect(() => {
      setValue('gearbox', '')
    }, [watch('vehicle')]);

    useEffect(() => {
      setValue('immatriculation', '')
    }, [watch('gearbox')]);
  // Obtenez les composants de la date
const newDateStart= new Date(reservation?.startAt as any as string) ;
const dayStart = newDateStart.getDate();
const month0Start = newDateStart.getMonth(); 
const monthStart = month0Start ? month0Start + 1: 0// Les mois commencent à partir de zéro
const yearStart = newDateStart.getFullYear();
const hoursStart = newDateStart.getHours();
const minutesStart = newDateStart.getMinutes();
const secondsStart = newDateStart.getSeconds();

// Formatez la date au format 'dd/MM/yyyy HH:mm:ss'
const formattedDateStart = `${dayStart}/${monthStart}/${yearStart} ${hoursStart}:${minutesStart}`;


// Obtenez les composants de la date
const newDateEnd= new Date(reservation?.endAt as any as string) ;
const dayEnd = newDateEnd.getDate();
const month0End = newDateEnd.getMonth(); 
const monthEnd = month0End ? month0End + 1: 0// Les mois commencent à partir de zéro
const yearEnd = newDateEnd.getFullYear();
const hoursEnd = newDateEnd.getHours();
const minutesEnd = newDateEnd.getMinutes();
const secondsEnd = newDateEnd.getSeconds();

// Formatez la date au format 'dd/MM/yyyy HH:mm:ss'
const formattedDateEnd = `${dayEnd}/${monthEnd}/${yearEnd} ${hoursEnd}:${minutesEnd}`;

  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h3">
        {reservation ? "Modifier une réservation" : "Ajouter une nouvelle réservation"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit as any ) } className="authentication-form" noValidate>
          <Col>
            <h4 className="text-md text-primary">Détails de la reservation</h4>
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="datetime-local"
                  name="startDateTime"
                  label={'Date et heure de début'}
                  containerClass={!reservation ? 'mb-3' : ''}
                />
                {reservation?.startAt && <label className="text-sm mb-3 d-flex justify-content-center">{formattedDateStart}</label> }
              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="datetime-local"
                  name="endDateTime"
                  label={'Date et heure de fin'}
                  containerClass={!reservation ? 'mb-3' : ''}
                />
                {reservation?.endAt && <label className="text-sm mb-3 d-flex justify-content-center">{formattedDateEnd}</label> }
              </Col>
              <Col xs={"5"} className='ml-auto' style={ { 'marginLeft': 'auto', } }>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="station"
                  label={'Station de départ et de retour'}
                  containerClass={'mb-3'}
                >
                  <option value="">Selectionner une station</option>
                  { stations.map(station => <option key={'station-' + station._id} value={station._id}>{ station.name }</option> )}
                </FormInput>
              </Col>
            </Row>
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="vehicle"
                  label={'Véhicule'}
                  containerClass={!reservation ? 'mb-3' : ''}
                >
                  <option value="">Selectionner un vehicule</option>
                  { availableVehiclesPerStation?.map(vehicleInstance => <option key={'vehicle-' + vehicleInstance?._id} value={vehicleInstance?._id}>{ vehicleInstance?.name + '-' + vehicleInstance?.brand }</option> ) }
                </FormInput>
                {reservation?.vehicleInstance?.vehicleId && availableVehiclesPerStation?.length && <label className="text-sm mb-3">{ availableVehiclesPerStation?.find(v => v._id === reservation.vehicleInstance?.vehicleId)?.name }</label> }
              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="gearbox"
                  label={'Boite de vitesse'}
                  containerClass={!reservation ? 'mb-3' : ''}
                >
                  <option value="">Selectionner la boite de vitesse</option>
                  { listGearBoxes.map((gearbox, idx) => <option key={idx + '-' + gearbox} value={gearbox}>{gearbox}</option> ) }
                </FormInput>
                {reservation && <label className="text-sm mb-3 d-flex justify-content-center">{ reservation.vehicleInstance?.vehicle.gearbox }</label> }
              </Col>
              <Col xs={"5"} className='ml-auto' style={ { 'marginLeft': 'auto', } }>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="immatriculation"
                  label={'Immatriculation'}
                  containerClass={!reservation ? 'mb-3' : ''}
                >
                  <option value="">Pas d'immatriculation</option>
                  { listImmatriculations.map((immatriculation, idx) => <option key={`immatriculation-${idx}-${immatriculation}`} value={immatriculation}>{immatriculation}</option> ) }
                </FormInput>
                {reservation && <label className="text-sm mb-3 d-flex justify-content-center">{ reservation.vehicleInstance?.immatriculation }</label> }
              </Col>
            </Row>
          </Col>

          {
            getValues('station') &&
            <Col>
              <h4 className="text-md text-primary">Options</h4>
              <Row className="justify-content-between">
                {
                  optionsBasedStation.map((option, idx) =>{
                    // eslint-disable-next-line no-eval
                    return (<Row key={option._id} className='w-50'>
                      <Col xs={'6'}>
                        {
                          option.name.includes('auto groupe') ?
                          <FormInput
                            register={register}
                            control={control}
                            errors={errors}
                            type="text"
                            name={"optionDesc_" + (+idx + 1) }
                            value={option.name}
                            disabled={true}
                            label={option.name.includes('auto groupe') ? 'Siege enfant' : 'Conductueur additionnel'}
                            containerClass={'mb-3'}
                          /> :
                        <FormInput
                          register={register}
                          control={control}
                          errors={errors}
                          type="text"
                          name={"optionDesc_" + (+idx + 1) }
                          label={option.name.includes('auto groupe') ? 'Siege enfant' : 'Conductueur additionnel'}
                          containerClass={'mb-3'}
                        />}
                      </Col>
                      <Col xs={'2'}>
                        <FormInput
                          register={register}
                          control={control}
                          errors={errors}
                          type="number"
                          name={`optionQty_${+idx +  1}`}
                          label={'Qté'}
                          min={0}
                          containerClass={'mb-3'}
                        />
                      </Col>
                      <Col xs={"3"} className='ml-auto'>
                        <FormInput
                          register={register}
                          control={control}
                          value={option._id}
                          type="hidden"
                          name={`optionId_${+idx + 1}`}
                        />
                        <FormInput
                          register={register}
                          control={control}
                          value={option.price}
                          errors={errors}
                          type="text"
                          label={'Montant'}
                          name={`optionAmount_${+idx + 1}`}
                          disabled={true}
                          containerClass={'mb-3'}
                        />
                      </Col>
                    </Row>)
                  })
                }
              </Row>
            </Col>
          }

          <Col>
            <h4 className="text-md text-primary">Client</h4>
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  name="clientEmail"
                  label={'Adresse mail'}
                  containerClass={'mb-3'}
                />
              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  name="reservationExtern"
                  label={'Numéro de réservation'}
                  containerClass={'mb-3'}
                />
              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  name="source"
                  label={'Source'}
                  containerClass={'mb-3'}
                  value={"Rentile"}
                  disabled={true}
                />
              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="reservationStatus"
                  label={'Status'}
                  containerClass={'mb-3'}
                  disabled={true}
                >
                  <option value="">Selectionner le status de la reservation</option>
                  <option value="PAYMENT_DONE">Paiement effectué</option>
                  <option value="VEHICLE_TAKEN">Validée</option>
                  <option value="IN_PROGRESS">En cours</option>
                  <option value="WAITING_PAYMENT" selected>En attente de paiement</option>
                  <option value="PAYMENT_INITIATED">En attente</option>
                  <option value="CLOSED">Terminée</option>
                  <option value="CANCELED">Annulée</option>
                </FormInput>
              </Col>
            </Row>
          </Col>

          <Col>
            <h4 className="text-md text-primary">Paiement</h4>
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="number"
                  name="reservationPrice"
                  label={'Prix de la location'}
                  containerClass={'mb-3'}
                />
                <label className="text-sm mb-3 d-flex justify-content-center">{reservation?.reservationPrice ? "Ancien Prix de location: " : ""}{reservation?.reservationPrice}</label>

              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="number"
                  name="reservationPaidAmount"
                  label={'Accompte versé'}
                  containerClass={'mb-3'}
                />
                <label className="text-sm mb-3 d-flex justify-content-center">{reservation?.reservationPaidAmount ? "Ancien acompte à verser: " : ""}{reservation?.reservationPaidAmount}</label>

              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="number"
                  value={reservationAmountToBePaid}
                  name="reservationAmountToBePaid"
                  label={'Reste à payer'}
                  disabled={true}
                  containerClass={'mb-3'}
                />
                <label className="text-sm mb-3 d-flex justify-content-center">
                  { reservation?.reservationAmountToBePaid ? ((["WAITING_PAYMENT", "PAYMENT_INITIATED"]).includes(reservation?.status as string) ? "Reste à payer par le client: ": " Montant déjà payé par le client: ") : ""}{reservation?.reservationAmountToBePaid}</label>

              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  value={reservationTotalToBePaid}
                  name="reservationTotalToBePaid"
                  label={'Montant total options incluse'}
                  disabled={true}
                  containerClass={'mb-3'}
                />
                {reservationNetToPay}d
                <label className="text-sm mb-3 d-flex justify-content-center"> 
                {reservation?.reservationTotalToBePaid ? "Montant total: " : ""}{reservation?.reservationTotalToBePaid}</label>

              </Col>
            </Row>
          </Col>

          <div className="mb-3 text-center d-flex flex-row-reverse ms-auto gap-2">
            <Button type="submit" variant='primary'
              disabled={isLoading || isSubmitting}
            >
              {(isLoading || isSubmitting) ? (
                <div className="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <>
                  Ajouter
                </>
              )}
            </Button>
            <Button variant="light" onClick={handleClose}>
              Annuler
            </Button>{' '}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
