import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { FormInput } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { VehicleState } from "../../redux/vehicle/reducers";
import { createVehicleThunk, loadGearBoxes, loadVehicleAvailblePerStation, loadVehicleImmatriculation, updateVehicleThunk } from "../../redux/vehicle/thunk";
import { VehicleTypestate } from "../../redux/vehicleTypes/reducers";
import { Contravention, Reservation, Vehicle, VehicleInstance, VehicleType, } from "../../types";
import { StationState } from '../../redux/stations/reducers';
import { loadStationsThunk } from '../../redux/stations/thunk';
import { OptionsState } from '../../redux/options/reducers';
import { loadOptionsBasedStationThunk } from '../../redux/options/thunk';
import { createRContraventionThunk, createReservationThunk, reservationEtats, updateReservation , updateReservationExterne} from '../../redux/reservations/thunk';
import { toISOLocal } from '../../utils/date';
import FileUploader from '../../components/FileUploader';
import moment from 'moment';
import 'moment/locale/fr'; // Importez la localisation française

interface Props {
  show: boolean;
  handleClose: () => void;
  vehicle?: Vehicle | null;
  reservation?: Reservation;
}

interface IFormValue {
  // libele: string;
  numeroAvis: number;
  dateAvis: string;
  montant: number;
  // montantFrais: number;
  contraventionType: "fps" | "avis"; // New field for contravention type

}

export default function ContraventionDialog({
  handleClose,
  show,
  vehicle = null,
  reservation
}: Props) {
  const dispatch = useAppDispatch();
  const { vehicleTypes } = useAppSelector(({ vehicleTypes }) => vehicleTypes) as VehicleTypestate;
  const { isLoading, availableVehiclesPerStation, listGearBoxes, listImmatriculations } = useAppSelector(({ vehicles }) => vehicles) as VehicleState;
  const { stations } = useAppSelector(({ stations }) => stations as StationState)
  const { optionsBasedStation } = useAppSelector(({ Options }) => Options as OptionsState)

  const [reservationNetToPay, setReservationNetToPay] = useState(0)
  const [reservationTotalToBePaid, setReservationTotalToBePaid] = useState(0)
  const [reservationAmountToBePaid, setReservationAmountToBePaid] = useState(0)

  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);

  const [photo, setPhoto] = useState<File | null>(null);

  // Nouvelle référence pour stocker le chemin du fichier
  const uploadedFilePathRef = useRef<string | null>(null);

  // Fonction pour mettre à jour la référence avec le chemin du fichier
  const setSelectedfiles = (files: FileList | null) => {
    if (files && files.length > 0) {
      // Utilisez URL.createObjectURL pour obtenir le chemin du fichier
      const filePath = URL.createObjectURL(files[0]);
      uploadedFilePathRef.current = filePath;
    } else {
      uploadedFilePathRef.current = null;
    }

    // Mettez à jour l'état des fichiers sélectionnés
    setSelectedfiles(files);
  };

  const reservationId = reservation?._id;

  const schemaResolver = yupResolver(
    yup.object().shape({
      // libele: yup.string().required("Veuillez saisir un libellé"),
      numeroAvis: yup.string(),
      dateAvis: yup.string(),
      // montant: yup.number().required("Veuillez saisir un montant"),
      // montantFrais: yup.number().required("Veuillez saisir un montant de frais"),
      contraventionType: yup.string().oneOf(["fps", "avis"]).required("Veuillez sélectionner le type de contravention"),
    })
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    getValues,
  } = useForm<IFormValue>({
    resolver: schemaResolver,
    defaultValues: {},
  });

  const [file, setFile] = useState(null);

  // const handleFileChange = (e: { target: { files: any[]; }; }) => {
  //   const selectedFile = e.target.files[0];
  //   setFile(selectedFile);
  // };

  const handleFileChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const selectedFile = e.target.files?.[0];
    setFile(selectedFile as any);
  };

  // Configurez la locale en français
  moment.locale('fr');

  const onSubmit = useCallback(
    async (data) => {
      const path0 = uploadedFilePathRef.current ;
        
       

          
        // Créez un nouvel objet FormData
        const formData = new FormData();
        formData.append('file', file as any);
        const contraventionData = { ...data, reservationId, file, path0, formData};

console.log(contraventionData)
        // Ajoutez chaque fichier sélectionné à FormData
        // if (selectedFiles) {
        //   for (let i = 0; i < selectedFiles.length; i++) {
        //     formData.append("files", selectedFiles[i]);
        //   }
        // }

        // Ajoutez d'autres champs de données à FormData
        // Object.entries(contraventionData).forEach(([key, value]) => {
        //   formData.append(key, value as any);
        // });

        // Envoyez FormData au backend
        // await dispatch(createRContraventionThunk(formData));
        
        dispatch(createRContraventionThunk(contraventionData));
        console.log('the data')
        console.log(contraventionData)
        handleClose(); // Ferme le modal après la soumission du formulaire
        data.reservationId = reservationId;
    },
    [dispatch, reservationId, file]
  );

  useEffect(() => {
    reset({});
  }, [reset, reservationId]);

  const { contraventionType } = watch();

  return (
    <Modal size="lg" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h3">
        <label className="text-center">
          {reservation ? "Ajouter une contravention" : "Ajouter une contravention"}
        </label>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit as any)} className="authentication-form" noValidate>
          <Row>
          <Col className="col-6">
          <Row>
          <label className="form-label h4 ">
                Type de contravention
            </label>
            <Col className="col-12">
              <label className="form-label h4">
                <FormInput
                  type="radio"
                  name="contraventionType"
                  value="fps"
                  label="Contravention de type FPS"
                  register={register}
                  containerClass="mb-3"
                  defaultChecked 
                />
              </label>
              </Col>
              <Col className="col-12">
              <label className="form-label h4">
                <FormInput
                  type="radio"
                  name="contraventionType"
                  value="avis"
                  label="Avis de contravention"
                  register={register}
                  containerClass="mb-3"
                  // Ajoutez ceci pour que "Avis de contravention" soit la valeur sélectionnée par défaut
                />
              </label>
            </Col>
            <Col className="col-12">
              {/* <FormInput
                register={register}
                control={control}
                errors={errors}
                type="file"
                name="files"
                onChange={(e) => setSelectedFiles(e.target.files)}
                className="form-control"
                containerClass={'mb-3'}
                multiple
              /> */}

                 {/* <input type="file" onChange={handleFileChange} /> */}

             <FileUploader
              maxFile={1}
              onFileUpload={(files) => setPhoto(files.length > 0 ? files[0] : null)}
              showPreview
            />
            </Col>
            {contraventionType === 'fps' && (
              <>
                {/* <Col className="col-12">
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="text"
                    name="libele"
                    // required
                    placeholder="Libellé de la contravention"
                    label={'Libellé de la contravention'}
                    containerClass={'mb-3'}
                  />
                </Col> */}
                <Col className="col-12">
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="number"
                    name="montant"
                    min={0}
                    placeholder="Montant de la contravention"
                    label={'Montant de la contravention'}
                    containerClass={'mb-3'}
                  />
                </Col>
              </>
            )}
            


            {/* <Col className="col-12">
              <FormInput
                register={register}
                control={control}
                errors={errors}
                type="number"
                name="montantFrais"
                min={0}
                placeholder="Frais de traitement d'une contravention"
                label={'Frais de traitement d\'une contravention'}
                containerClass={'mb-3'}
                value={15}
                disabled
              />
            </Col> */}
            </Row>
            </Col>
            <Col className="col-6 border mb-3">
              <Row>
                <Col className="col-4 mt-3">
                  <Row>
                  <img
                    style={{
                      width: "100%",
                      height: "150px",
                      objectFit: 'cover',
                      objectPosition: 'center',
                      backgroundPosition: 'center',
                      backgroundSize: 'cover',
                    }}
                    src={reservation?.vehicleInstance?.vehicle.absolutePhotoUrl}
                    alt={`reservation ${reservation?.vehicleInstance?.vehicle.name}`}
                  />
                  </Row>
                </Col>
                <Col className="col-8 mt-3">
                  <Row>
                  {
                    reservation?.vehicleInstance
                    ?
                    <>
                    <span className="fw-bold">{`${reservation?.vehicleInstance?.vehicle?.name} ${reservation?.vehicleInstance?.vehicle?.brand}`}</span>
                    <br/>
                    <span className="fw-bold" style={{color:'grey'}}>{`${reservation?.vehicleInstance?.immatriculation}`}</span>
                    <br/>
                    <span className="" style={{color:'grey'}}>{`--> ${moment(reservation?.startAt).format('DD MMM YYYY [à] HH:mm')}`}</span>
                    <br/>
                    <span className="" style={{color:'grey'}}>{`<--${moment(reservation?.endAt).format('DD MMM YYYY [à] HH:mm')}`}</span>
                    </>
                    :
                    <span className="fw-bold">{`${reservation?.vehicle?.name} ${reservation?.vehicle?.brand} - "Pas d'imatriculation attribuée"`}</span>

                  }
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="mb-3 text-center d-flex flex-column gap-2">
            <Button type="submit" variant="primary" disabled={isSubmitting}>
            Débiter et facturer
            </Button>
            <Button variant="light" onClick={handleClose}>
              Annuler
            </Button>{' '}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );

}