import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
import { setCancelReservationModal, setEtas, setValidateReservationModal, setOpenReservationEditionModal } from '../../redux/reservations/actions';
import { ReservationsState } from '../../redux/reservations/reducers';
import { loadReservationsHistoryThunk } from '../../redux/reservations/thunk';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Reservation, ReservationHistory } from '../../types';
import { formatDateLocal } from '../../utils/date';
import { formatCurrency } from "../../utils/number";
import { reservationsStatusLabel, reservationStatusClass } from '../../utils/reservation';
import { useParams } from 'react-router-dom';
import DetailsDialog from './DetailsDialog';


const sizePerPageList = [
  {
    text: '5',
    value: 5,
  },
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  },
  {
    text: '50',
    value: 50,
  }
];

const ReservationsHistory = () => {
  const { reservationId } = useParams<{ reservationId: string }>();
  const dispatch = useAppDispatch();
  const { reservationsHistory } = useAppSelector(({ Reservations }) => Reservations) as ReservationsState;
  const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
  const handleDetailShow = useCallback(() => setShowDetailsDialog(true), [setShowDetailsDialog])
  const handleDetailClose = useCallback(() => setShowDetailsDialog(false), [setShowDetailsDialog])


  const handleOpenCloseReservationEdition = useCallback((payload: boolean) =>
    () => dispatch(setOpenReservationEditionModal(payload)), [dispatch])

  useEffect(() => {
    if (reservationsHistory.length === 0) {
      // Utilisez la nouvelle fonction pour charger l'historique des réservations
      dispatch(loadReservationsHistoryThunk(reservationId));
    }
  }, [dispatch, reservationId, reservationsHistory.length]);

  const pad = (value: any) => {
    return value < 10 ? '0' + value : value;
  }
  const createOffset = (date: any) => {
    var sign = (date?.getTimezoneOffset() > 0) ? -1 : +1;
    var offset = Math.abs(date?.getTimezoneOffset());
    var hours = pad(Math.floor(offset / 60));
    var minutes = pad(offset % 60);
    return sign * hours;
  }

  const spiteISODate = (date: Date) => {
    return date?.toString().split("T")[0];
  }

  const spiteISOTime = (date: Date) => {
    const longTime = date?.toString().split("T")[1];
    const time = longTime?.split(":");
    if(time != undefined){
      return `${time[0]}:${time[1]}`;
    }
    return ``;
  }

  const getReservationDateUTC = (date: any) => {
    const reservationDate = new Date(date);
    return reservationDate.setHours(reservationDate.getHours() - createOffset(reservationDate));
  }

  const columns = useMemo(() => [
    {
      Header: 'Utilisateur',
      accessor: '',
      Cell: ({ row }: { row: any, [x: string]: any }) => {

        return (
          <div className="d-flex gap-2">
            <div
              className="rounded-circle"
              style={{
                width: "60px",
                height: "60px",
                borderRadius: "50%",
                overflow: 'hidden'
              }}>
              {row?.original?.user?.photoUrl ? (
                <img style={{
                  objectFit: 'cover',
                  backgroundPosition: 'center',
                  backgroundSize: "cover",
                  width: "100%",
                  height: "100%",
                }} src={row?.original?.user?.photoUrl} alt={row?.original?.user?.name} />
              ) : (
                <div
                  className="bg-primary border-2 border-secondary d-flex align-items-center justify-content-center"
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                >
                  <h5 className="fw-bold text-white">
                    {row?.original?.user?.name[0] + "." + row?.original?.user?.firstName[0]}
                  </h5>
                </div>
              )}
            </div>
            <div className="d-flex flex-column gap-1">
              <div className="d-flex gap-1">
                <span className="fw-bold">{row?.original?.user?.name}</span>
                <span className="fw-bold">{row?.original?.user?.firstName}</span>
              </div>

              <span>{row?.original?.user?.phone}</span><br/>
              <span>{row?.original?.clientEmail ? row?.original?.clientEmail : row?.original?.user?.emails}</span>

            </div>
          </div>
        )
      },
    },
    {
      accessor: '',
      Header: "Voiture",
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <div className="flex text-center">
            <div
              className="border p-1"
              style={{
                width: "100%",
                height: "100px",
                overflow: 'hidden'
              }}
            >
              <img style={{
                objectFit: 'contain',
                backgroundPosition: 'center',
                backgroundSize: "contain",
                width: "100%",
                height: "100%",
              }}
                src={row?.original?.vehicleInstance?.vehicle.absolutePhotoUrl}
                alt={row?.original?.vehicleInstance?.vehicle.name}
              />
            </div>
            <strong className="mt-2">{row?.original?.vehicleInstance?.immatriculation}</strong>
            <div className="d-flex flex-column gap-2">
              <h1 className="h5 mb-0 pb-0">{row.original?.vehicleInstance?.name}</h1>
            </div>
          </div>
        );
      },
      disableGlobalFilter: true,
    },
    {
      Header: "Status",
      accessor: 'status',
      sort: true,
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <>
          { row?.original?.clientEmail &&
            <>
            <span
              className={`badge py-1 badge-soft-dark`}
            >
              {row?.original?.clientEmail ? 'Réservation Externe ' : ""}
            </span><br/><br/>
            </>
          }

          <span
            className={`badge py-1 ${reservationStatusClass(row?.original?.status)}`}
          >
            {reservationsStatusLabel(row?.original?.status)}
          </span>
          </>
        )
      },
    },
    {
      Header: "Montant",
      accessor: 'amount',
      sort: true,
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span className="text-uppercase fw-bold">
            {row?.original?.clientEmail ? formatCurrency(row?.original?.reservationTotalToBePaid) : formatCurrency(row?.original?.amount)}
          </span>
        )
      },
    },
    {
      Header: 'Départ',
      accessor: 'startAt',
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span>{formatDateLocal(spiteISODate(row?.original?.startAt))} {"à"} {spiteISOTime(row?.original?.startAt)}</span>
        )
      },
      sort: true,
    },
    {
      Header: 'Retour',
      accessor: 'endAt',
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        return (
          <span>{formatDateLocal(spiteISODate(row?.original?.endAt))} {"à"} {spiteISOTime(row?.original?.endAt)}</span>
        )
      },
      sort: true,
    },
    {
      id: "Actions",
      accessor: '',
      Header: "Action",
      Cell: ({ row }: { row: any, [x: string]: any }) => {
        const reservation = row.original as Reservation;

        return (
          <div className="d-flex align-items-center gap-2">

            {reservation.user ?
            <button
            className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
            onClick={() => {
              dispatch(setEtas([]));
              setReservation(row.original);
              handleDetailShow();
            }}
          >
            <span className="mr-2">
              <i className="bi bi-eye-fill"></i>
            </span>
          </button>
          :
          ""

            }

          </div>
        );
      },
      disableGlobalFilter: true,
    },
  ], [reservation]);

  return (
    <>
      <PageTitle
        breadCrumbItems={[]}
        title={"Historique des réservations"}
      />

      <Row>
        <Col>
          <Card>
            <Card.Body>
              <Table
                columns={columns}
                data={reservationsHistory}
                pageSize={5}
                sizePerPageList={sizePerPageList}
                isSortable={true}
                pagination={true}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>

      {/* Create modal */}

      {reservation ? (
        <>
          <DetailsDialog
            handleClose={handleDetailClose}
            reservation={reservation}
            show={showDetailsDialog}
          />
        </>
      ) : (<></>)}
    </>
  );
};

export default ReservationsHistory;