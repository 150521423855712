import { yupResolver } from "@hookform/resolvers/yup";
import { Col, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FormInput } from "../../components";
import { Reservation, Vehicle, VehicleInstance } from "../../types";
import { formatDateLocal, formatTime, toISOLocal } from "../../utils/date";
import { formatCurrency } from "../../utils/number";
import * as yup from 'yup';
import { useCallback, useEffect } from "react";
import FeatherIcons from 'feather-icons-react';
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { getAvailableVehicleInstanceThunk } from "../../redux/vehicleInstance/thunk";
import { VehicleState } from "../../redux/vehicle/reducers";
import { VehicleInstanceState } from "../../redux/vehicleInstance/reducers";
import { ReservationsState } from "../../redux/reservations/reducers";
import Loader from "../../components/Loader";
import { reservationEtats, updateReservation } from "../../redux/reservations/thunk";
import { EtatStatusLabel, etaStatusClass } from "../../utils/reservation";
import config from "../../config";

interface Props {
  reservation: Reservation;
}

export default function ReservationCard({ reservation }: Props) {

  const { isUpdateLoading, etats } = useAppSelector(({ Reservations }) => Reservations) as ReservationsState;

  const { vehicleInstancesImmat } = useAppSelector(({ vehicleInstances }) => vehicleInstances) as VehicleInstanceState;

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAvailableVehicleInstanceThunk(reservation.vehicleInstance?.station?._id, reservation.startAt.toString(), reservation.endAt.toString()));
    dispatch(reservationEtats(reservation._id));
  }, [dispatch]);

  const reservationStartDate = new Date(reservation.startAt);
  const reservationEndDate = new Date(reservation.endAt);

  const pad = (value: any) => {
    return value < 10 ? '0' + value : value;
  }
  const createOffset = (date: any) => {
    var sign = (date.getTimezoneOffset() > 0) ? -1 : +1;
    var offset = Math.abs(date.getTimezoneOffset());
    var hours = pad(Math.floor(offset / 60));
    var minutes = pad(offset % 60);
    return sign * hours;
  }

  const spiteISODate = (date: Date) => {
    return date.toString().split("T")[0];
  }

  const spiteISOTime = (date: Date) => {
    const longTime = date.toString().split("T")[1];
    const time = longTime.split(":");
    return `${time[0]}:${time[1]}`;
  }

  const getDateByTimeZone = (date: any) => {
    return date.setHours(date.getHours() + createOffset(date));
  }

  const reservationStartDateUTC = reservationStartDate.setHours(reservationStartDate.getHours() - createOffset(reservationStartDate));
  const reservationEndDateUTC = reservationStartDate.setHours(reservationEndDate.getHours() - createOffset(reservationEndDate));

  const onSubmit = useCallback((data) => {
    let startAt = toISOLocal(data.debut).split("T");
    let time = startAt[1].split(":")[0]+':'+startAt[1].split(":")[1];
    let endAt = toISOLocal(data.fin).split("T");
    let endTime = endAt[1].split(":")[0]+':'+endAt[1].split(":")[1];
    let formatStartAt = new Date(getDateByTimeZone(new Date(startAt[0]+" "+time)));
    let formatEndAt = new Date(getDateByTimeZone(new Date(endAt[0]+" "+endTime)));
    data.debut = formatStartAt;
    data.fin = formatEndAt;
    dispatch(updateReservation(reservation._id, data));
  }, [dispatch]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      immatriculation: yup.string().typeError("Veuillez choisir une immatriculation").required("requis"),
      debut: yup.date().typeError("Veuillez sélectionner une date de départ").required("requis"),
      fin: yup.date().typeError("Veuillez sélectionner une date de retour").required("requis"),
      status: yup.string().typeError("Veuillez choisir un status").required("requis"),
    })
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<{
    immatriculation: string | null;
    debut: Date | null;
    fin: Date | null;
    unlockVehicule: boolean;
    status: string;
  }>({
    resolver: schemaResolver,
    defaultValues: reservation.vehicle !== null ? {
      immatriculation: reservation.vehicleInstance?.immatriculation,
      debut: reservation.startAt,
      fin: reservation.endAt,
      unlockVehicule: reservation.unlockVehicule,
      status: reservation.status
    } : {}
  });

  /**
   * Use Form hooks.
   */
  useEffect(() => {
    reset({});
  }, [reset, reservation]);

  return (
    <div style={{ zIndex: '9999', paddingTop:'30px' }} className="border-top">
      <h4>
        Details de la reservation Nº {reservation.reservationNumber}
      </h4>
      <div className="d-flex gap-2 border-bottom mb-2">
        <div
          className="rounded-circle border border-primary mb-1 border-bottom"
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            overflow: 'hidden'
          }}>
          {reservation.user?.photoUrl ? (
            <img style={{
              objectFit: 'cover',
              backgroundPosition: 'center',
              backgroundSize: "cover",
              width: "100%",
              height: "100%",
            }} src={reservation.user?.photoUrl} alt={reservation.user?.name} />
          ) : (
            <div
              className="bg-primary border-2 border-secondary d-flex align-items-center justify-content-center"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            >
              <h5 className="fw-bold text-white">
                {reservation.user?.name[0] + "." + reservation.user?.firstName[0]}
              </h5>
            </div>
          )}
        </div>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex gap-1">
            <span className="fw-bold">{reservation.user?.name}</span>
            <span className="fw-bold">{reservation.user?.firstName}</span>
          </div>

          <span>{reservation.user?.email}</span>
        </div>
      </div>
      <h1 className="h6">Réservation</h1>
      {
        reservation.vehicleInstance
        ?
        <span className="fw-bold">{`${reservation.vehicleInstance?.vehicle?.name} ${reservation.vehicleInstance?.vehicle?.brand} - ${reservation.vehicleInstance?.immatriculation}`}</span>
        :
        <span className="fw-bold">{`${reservation.vehicle?.name} ${reservation.vehicle?.brand} - "Pas d'imatriculation attribuée"`}</span>

      }
      {/* <span className="fw-bold">{`${reservation.vehicleInstance?.vehicle?.name} ${reservation.vehicleInstance?.vehicle?.brand} - ${reservation.vehicleInstance?.immatriculation}`}</span> */}
      <div className="d-flex gap-2">
        <div
          className="mb-3 border"
          style={{
            width: "250px",
            height: "auto",
            padding: '5px',
            overflow: 'hidden',
          }}
        >
          <img
            style={{
              width: "100%",
              height: "150px",
              objectFit: 'cover',
              objectPosition: 'center',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            src={reservation.vehicleInstance?.vehicle.absolutePhotoUrl}
            alt={`reservation ${reservation.vehicleInstance?.vehicle.name}`}
          />
          <h4>Locataire</h4>
          <div className="d-flex gap-1 mt-2">
            <span>Nom: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.firstName} {reservation.user?.name}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Adresse: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.address}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Code postal: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.postalCode}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Ville: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.city}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Téléphone: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.phone}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Email: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.email}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Permis Nº: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.permisNumber ? reservation.user?.permisNumber : '-'}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Date d'obtention: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation.user?.obtainedAt ? (new Date(reservation.user?.obtainedAt)).toLocaleDateString('fr') : '-'}
            </span>
          </div>

          { reservation.secondUser && (
              <div className="mt-3">
                <h5>Locataire secondaire</h5>
                <div className="d-flex gap-1 mt-3">
                  <span>Nom: </span>
                  <span style={{fontWeight: 'bold'}}>
                    {reservation.secondUser.firstName} {reservation.secondUser.name}
                  </span>
                </div>
                <div className="d-flex gap-1 mt-2">
                  <span>Email: </span>
                  <span style={{fontWeight: 'bold'}}>
                    {reservation.secondUser.email}
                  </span>
                </div>
              </div>
            )
          }

        </div>
        <div className="flex-grow-1 d-flex flex-column gap-1">
          <div className="d-flex gap-2">
            <form onSubmit={handleSubmit(onSubmit)} className="authentication-form" noValidate>
              <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="select"
                    name="immatriculation"
                    placeholder="Immatriculation"
                    label={'Immatriculation'}
                    containerClass={'mb-1'}
                  >
                    <option disabled>Selectionner</option>
                    <option key={reservation.vehicleInstance?._id} value={reservation.vehicleInstance?.immatriculation} selected>{reservation.vehicleInstance?.immatriculation}</option>
                    {vehicleInstancesImmat?.map((c: VehicleInstance) => {
                      if(c) {
                        return (
                          c.immatriculation == reservation.vehicleInstance?.immatriculation ?
                            <option key={c._id} value={c.immatriculation} selected>{c.immatriculation}</option>
                          :
                            <option key={c._id} value={c.immatriculation}>{c.immatriculation}</option>
                        )
                      }
                    })}
                  </FormInput>
                </Col>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="select"
                    name="status"
                    placeholder="Status"
                    label={'Status'}
                    containerClass={'mb-1'}
                    id="state"
                    defaultValue={reservation.status}
                  >
                    <option disabled>Selectionner</option>
                    <option value="CANCELED">Annulée</option>
                    <option value="CLOSED" >Terminée</option>
                    <option value="VEHICLE_TAKEN">Validée</option>
                    <option value="IN_PROGRESS">En cours</option>
                    <option value="WAITING_PAYMENT">En attente de paiement</option>
                    <option value="PAYMENT_INITIATED">En attente</option>
                    <option value="PAYMENT_DONE">Paiement effectué</option>
                  </FormInput>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="datetime-local"
                    name="debut"
                    placeholder="Début"
                    label={'Début'}
                    containerClass={'mb-1'}
                    defaultValue={reservation?.startAt.toString().split(".")[0]}
                  />
                </Col>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="datetime-local"
                    name="fin"
                    placeholder="Fin"
                    label={'Fin'}
                    containerClass={'mb-1'}
                    defaultValue={reservation?.endAt.toString().split(".")[0]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="checkbox"
                    name="unlockVehicule"
                    label={'Voiture déverrouillée'}
                    containerClass={'mb-1 mt-2'}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary rounded-3 d-flex gap-2 fw-bold text-uppercase mt-2 mb-2"
                  >
                    {isUpdateLoading ? (
                      <Loader />
                    ) : (
                      <>
                        Sauvegarder
                      </>
                    )}
                  </button>
                </Col>
              </Row>
            </form>
          </div>
          {reservation.clientEmail ?
          <>
          <div className="d-flex gap-2">
            <span><i className="bi bi-hash h4"></i></span>
            <span className="">
            N° de réservation externe
              {" : "}
              {reservation.reservationExtern}
            </span>
          </div>
          </>
          :
          ''
          }

          <div className="d-flex gap-2">
            <span><i className="bi bi-geo-alt-fill h4"></i></span>
            <span className="">
              Station
              {" : "}
              {reservation.vehicleInstance?.station2debut <= reservation?.startAt && reservation?.vehicleInstance?.station2fin >= reservation?.endAt ?
              reservation?.vehicleInstance?.station2?.name :
              reservation.vehicleInstance?.station?.name}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span><i className="bi bi-calendar2-day h4"></i></span>
            <span className="">
              {formatDateLocal(spiteISODate(reservation.startAt))} {"à"} {spiteISOTime(reservation.startAt)}
              {" - "} {formatDateLocal(spiteISODate(reservation.endAt))} {"à"} {spiteISOTime(reservation.endAt)}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span><i className="bi bi-shield-plus h4"></i></span>
            <span className="">
              {reservation.insuranceForfait.name}
              {" : "}
              <span>{formatCurrency(reservation.insuranceForfait.price)}</span>
            </span>
          </div>

          <div className="d-flex gap-2">
            <span><i className="bi bi-signpost h4"></i></span>
            <span className="">
              Kilometres inclus
              {" : "}
              {reservation.clientEmail ? "illimité" : reservation.kmForfait.includedKm + " Km"}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span><i className="bi bi-signpost h4"></i></span>
            <span className="">
              Kilometres additionels
              {" : "}
              {reservation.additionalKm} Km
            </span>
          </div>

          <div className="d-flex gap-2">
            <span><i className="bi bi-signpost h4"></i></span>
            <span className="">
              prix journalier
              {" : "}
              {formatCurrency(reservation.vehicleInstance?.vehicle.dailyPrice)}
            </span>
          </div>

          { reservation.option?.length > 0 && <strong className="fw-bold mt-2 mb-2">Options : </strong> }

          {
            reservation.option?.map((option, index) => (
              <div className="d-flex gap-2">
                <span><i className="bi bi-tag h4"></i></span>
                <span className="">
                  {option.name}
                  {" - "}
                  {formatCurrency(option.price)}
                </span>
              </div>
            ))
          }


          <div className="d-flex justify-content-between pt-1 border-top">
            <strong className="fw-bold h4">Total : </strong>
            <span className="fw-bold h4">
              {reservation.clientEmail ? formatCurrency(reservation.reservationTotalToBePaid as any) : formatCurrency(reservation.amount)}
            </span>
          </div>

          {reservation.clientEmail ?
          <>
          <div className="d-flex justify-content-between pt-1 border-top">
          <strong className="fw-bold h4">Reste à payer : </strong>
          <span className="fw-bold h4">
            {formatCurrency(reservation.amount)}
          </span>
        </div>

        </>
          :
          ''
          }

          {
            reservation.kmStarter ?
              <div className="d-flex gap-2 pt-1 border-top">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Kilometres début
                  {" - "}
                  {reservation.kmStarter} Km
                </span>
              </div>
            : null
          }

          {
            reservation.kmStarter ?
              <div className="d-flex gap-2">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Kilometres fin
                  {" - "}
                  {reservation.kmEnded} Km
                </span>
              </div>
            : null
          }

          {
            reservation.kmStarter ?
              <div className="d-flex gap-2">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Carburant début
                  {" - "}
                  {reservation.carburantStarter}%
                </span>
              </div>
            : null
          }

          {
            reservation.kmStarter ?
              <div className="d-flex gap-2">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Carburant fin
                  {" - "}
                  {reservation.carburantEnded}%
                </span>
              </div>
            : null
          }

          <div className="d-flex gap-2 mt-1">
            <a href="https://www.usagers.antai.gouv.fr/demarches/saisienumero?lang=fr" style={{textDecoration: 'underline'}} target="_blank">Traiter une contravention</a>
          </div>
          {/* <div className="d-flex gap-2 mt-1">
            <a href={`${config?.PUBLIC_URL}/contravention/${reservation?._id}`}  style={{textDecoration: 'underline'}} target="_blank">Traiter une contravention</a>
          </div> */}
          <div className="d-flex gap-2 mt-2">
            <a href={reservation.endContratUrl ? `https://dashboardapp.easygo.co/api/${reservation.endContratUrl}` : `https://dashboardapp.easygo.co/api/${reservation.startContratUrl}`} style={{textDecoration: 'underline'}} target="_blank">Contrat de location</a>
          </div>
        </div>
      </div>
      {
        reservation.user?.driverLicencePhotosUrl!.length > 0 ?
          <>
            <h1 className="h5">Permis de conduire</h1>
            <div className="d-flex justify-content-between">
              <a
                href={reservation.user?.driverLicencePhotosUrl![0].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation.user?.driverLicencePhotosUrl![0].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
              <a
                href={reservation.user?.driverLicencePhotosUrl![1].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation.user?.driverLicencePhotosUrl![1].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
            </div>
          </>
        : null
      }

      { (reservation.secondUser && reservation.secondUser.driverLicencePhotosUrl!.length) && (
          <>
            <h1 className="h5">Permis de conduire secondaire</h1>
            <div className="d-flex justify-content-between">
              <a
                href={reservation.secondUser.driverLicencePhotosUrl![0].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation.secondUser.driverLicencePhotosUrl![0].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
              <a
                href={reservation.secondUser.driverLicencePhotosUrl![1].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation.secondUser.driverLicencePhotosUrl![1].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
            </div>
          </>
        )
      }
      <>
        <h1 className="h5 mt-3 mb-3">{etats.length > 0 && ('Dommage & états des lieux')}</h1>
        <div>
          {
            etats?.map((etat) => {
              return(
                <>
                  <span className={`badge mb-2 mt-3 py-1 ${etaStatusClass(etat.type)}`}>
                    {EtatStatusLabel(etat.type)}
                  </span>
                  <p>{etat.description}</p>
                  <div className="d-flex justify-content-between">
                    {
                      etat.photos?.map((photo) => {
                        return(
                          <a
                            href={photo}
                            target="_blank"
                            className="border border-primary mb-1 border-bottom"
                            style={{
                              width: "33.333%",
                              height: "250px",
                              overflow: 'hidden'
                            }}
                          >
                            <img
                              src={photo}
                              alt="Permis de conduire"
                              style={{
                                objectFit: 'cover',
                                backgroundPosition: 'center',
                                backgroundSize: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </a>
                        )
                      })
                    }
                  </div>
                </>
              )
            })
          }
        </div>
      </>
    </div>
  )
}
