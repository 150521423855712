import { VehicleInstance } from "../../types";

export enum VEHICLE_INSTANCE_ACTIONS {
  VEHICLE_INSTANCE_LIST = "VEHICLE_INSTANCE_LIST",
  VEHICLE_INSTANCE_ERROR = "VEHICLE_INSTANCE_ERROR",
  VEHICLE_INSTANCE_OPTIONS_MODAL = "VEHICLE_INSTANCE_OPTIONS_MODAL",
  VEHICLE_INSTANCE_UPDATE_MODAL = "VEHICLE_INSTANCE_UPDATE_MODAL",
  VEHICLE_INSTANCE_CREATE_MODAL = "VEHICLE_INSTANCE_CREATE_MODAL",
  VEHICLE_INSTANCE_CALENDAR_MODAL = "VEHICLE_INSTANCE_CALENDAR_MODAL",
  VEHICLE_INSTANCE_LOADING = "VEHICLE_INSTANCE_LOADING",
  VEHICLE_INSTANCE_LIST_ALL = "VEHICLE_INSTANCE_LIST_ALL",
  VEHICLE_INSTANCE_DELETE_LOADING = "VEHICLE_INSTANCE_DELETE_LOADING"
}

export interface VehicleInstanceState {
  vehicleInstances: VehicleInstance[];
  showCreateModal: boolean;
  showCalendarModal: boolean;
  showUpdateModal: boolean;
  isLoading: boolean;
  error: { [x: string]: string } | string | null;
  isDeletedLoading: boolean;
  vehicleInstancesImmat: VehicleInstance[];
}

const initialState: VehicleInstanceState = {
  vehicleInstances: [],
  showCreateModal: false,
  showUpdateModal: false,
  isLoading: false,
  isDeletedLoading: false,
  error: null,
  vehicleInstancesImmat: [],
  showCalendarModal: false,
};

export interface VehicleActionType {
  type: keyof typeof VEHICLE_INSTANCE_ACTIONS;
  payload: any;
}

const VehicleReducer = (
  state: VehicleInstanceState = initialState,
  action: VehicleActionType
): VehicleInstanceState => {
  switch (action.type) {
    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_LIST:
      return {
        ...state,
        vehicleInstances: action.payload,
        isLoading: false,
      };

    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_ERROR:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };

    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_CREATE_MODAL:
      return {
        ...state,
        showCreateModal: action.payload,
      };

    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_CALENDAR_MODAL:
      return {
        ...state,
        showCalendarModal: action.payload,
      };

    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_UPDATE_MODAL:
      return {
        ...state,
        showUpdateModal: action.payload,
      };

    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_LIST_ALL:
      return {
        ...state,
        vehicleInstancesImmat: action.payload,
        isLoading: false,
      };

    case VEHICLE_INSTANCE_ACTIONS.VEHICLE_INSTANCE_DELETE_LOADING:
      return {
        ...state,
        isDeletedLoading: action.payload,
      };

    default:
      return {
        ...state,
      };
  }
};

export default VehicleReducer;
