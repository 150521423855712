import { etaStatus, ReservationStatus } from "../types";

export const reservationsStatusLabel = (status: ReservationStatus): string => {
  let label = "";

  switch (status) {
    case "CANCELED":
      label = "Annulée";
      break;
    case "CLOSED":

      label = "Terminée";
      break;

    case "PAYMENT_DONE":
      label = "Paiement effectué";
      break;
    case "VEHICLE_TAKEN":
      label = "Validée";
      break;
    case "IN_PROGRESS":
      label = "En cours";
      break;
    case "WAITING_PAYMENT":
      label = "En attente de paiement";
      break;

    case "PAYMENT_INITIATED":
      label = "En attente";
      break;

    default:
      label = "Toutes";
      break;
  }

  return label;
};

export const reservationStatusClass = (status: ReservationStatus): string => {
  let color = "";

  switch (status) {
    case "CANCELED":
      color = "badge-soft-dark";
      break;

    case "CLOSED":
      color = "badge-soft-dark";
      break;

    case "PAYMENT_DONE":
      color = "badge-soft-info";
      break;

    case "VEHICLE_TAKEN":
      color = "badge-soft-info";
      break;

    case "IN_PROGRESS":
      color = "badge-soft-success";
      break;

    case "WAITING_PAYMENT":
      color = "badge-soft-warning";
      break;

    case "PAYMENT_INITIATED":
      color = "badge-soft-warning";
      break;

    case "PAYMENT_INITIATED":
      color = "badge-soft-warning";
      break;

    default:
      break;
  }

  return color;
};

export const etaStatusClass = (status: etaStatus): string => {
  let color = "";

  switch (status) {
    case "START":
      color = "badge-soft-warning";
      break;

    case "PROGRESS":
      color = "badge-soft-success";
      break;

    case "END":
      color = "badge-soft-dark";
      break;

    default:
      break;
  }

  return color;
};

export const EtatStatusLabel = (status: etaStatus): string => {
  let label = "";

  switch (status) {
    case "START":
      label = "Avant la location";
      break;

    case "PROGRESS":
      label = "Pendant la location";
      break;

    case "END":
      label = "Après la location";
      break;

    default:
      label = "Toutes";
      break;
  }
  return label;
};
