import { yupResolver } from '@hookform/resolvers/yup';
import { useCallback, useEffect, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { FormInput } from "../../components";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import { VehicleState } from "../../redux/vehicle/reducers";
import { createVehicleThunk, loadGearBoxes, loadVehicleAvailblePerStation, loadVehicleImmatriculation, updateVehicleThunk } from "../../redux/vehicle/thunk";
import { VehicleTypestate } from "../../redux/vehicleTypes/reducers";
import { Reservation, Vehicle, VehicleInstance, VehicleType, } from "../../types";
import { StationState } from '../../redux/stations/reducers';
import { loadStationsThunk } from '../../redux/stations/thunk';
import { OptionsState } from '../../redux/options/reducers';
import { loadOptionsBasedStationThunk } from '../../redux/options/thunk';
import { createReservationThunk, reservationEtats, updateReservation , updateReservationExterne} from '../../redux/reservations/thunk';
import { toISOLocal } from '../../utils/date';
import moment from 'moment';

interface Props {
  show: boolean;
  handleClose: () => void;
  vehicle?: Vehicle | null;
  reservation?: Reservation;
}

interface IFormValue {
  startDateTime:  Date | null;
  endDateTime: string;
  station: string;
  vehicle: string;
  gearbox: string | null; // boites de vitesse => "auto" | "manuel" | "semi-auto"
  immatriculation: string;
  optionId: string;
  optionAmount_1: string;
  optionAmount_2: string;
  optionAmount_3: string;
  optionAmount_4: string;
  clientEmail: string;
  reservationExtern: string;
  source: string;
  reservationStatus: string;
  reservationPrice: number;
  reservationPaidAmount: number;
  reservationAmountToBePaid: number;
  reservationTotalToBePaid: number;
}

export default function CreateFormDialog({
  handleClose,
  show,
  vehicle = null,
  reservation
}: Props) {
  const dispatch = useAppDispatch();
  const { vehicleTypes } = useAppSelector(({ vehicleTypes }) => vehicleTypes) as VehicleTypestate;
  const { isLoading, availableVehiclesPerStation, listGearBoxes, listImmatriculations } = useAppSelector(({ vehicles }) => vehicles) as VehicleState;
  const { stations } = useAppSelector(({ stations }) => stations as StationState)
  const { optionsBasedStation } = useAppSelector(({ Options }) => Options as OptionsState)

  const [reservationNetToPay, setReservationNetToPay] = useState(0)
  const [reservationTotalToBePaid, setReservationTotalToBePaid] = useState(0)
  const [reservationAmountToBePaid, setReservationAmountToBePaid] = useState(0)
  
  const [defaultEndDate, setDefaultEndDate] = useState<string>("");
  const [defaultStartDate, setDefaultStartDate] = useState<string>("");
  const [dateAreSet, setDateAreSet] = useState(false); 
  const [formattedDateStartAffiche, setFormattedDateStartAffiche] = useState<string>("");
let formattedDateEndAffiche = "";


useEffect(() => {
  if (reservation) {
    console.log('this réservation : ', reservation)
    if (reservation.endAt) {
      const endDatePlus3Hours = moment(reservation.endAt)
        .add(3, 'hours')
        .utcOffset('+0200')
        .format("YYYY-MM-DDTHH:mm");
      setDefaultEndDate(endDatePlus3Hours);
    }
    if (reservation.startAt) {
      const startDateMoins3Hours = moment(reservation.startAt)
        .add(-3, 'hours')
        .format("YYYY-MM-DDTHH:mm");
      setDefaultStartDate(startDateMoins3Hours);
    }
  }
}, [reservation]);

useEffect(() => {
  if (!reservation) {
    setDateAreSet(true);
  }
}, []);

useEffect(() => {
  if (defaultStartDate && defaultEndDate) {
    console.log('defaultStartDate', defaultStartDate)
    setDateAreSet(true);
  }
}, [defaultStartDate, defaultEndDate]);

useEffect(() => {
  if (dateAreSet) {
    const dateStart = moment(defaultStartDate);
    setFormattedDateStartAffiche(dateStart.format("DD/MM/YYYY HH:mm"));
    const dateEnd = moment(defaultEndDate);
    // setFormattedDateEndAffiche(dateEnd.format("DD/MM/YYYY HH:mm"));
  }
}, [dateAreSet, defaultStartDate, defaultEndDate]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      startDateTime: yup.date().required("Veuillez saisir une date et heure de début de location"),
    endDateTime: yup.string().required("Veuillez saisir une date et heure de fin de location"),
    station: yup.string().required("Veuillez choisir une station"),
    vehicle: yup.string().required("Veuillez choisir un véhicule"),
    gearbox: yup.string().required("Veuillez choisir une boîte de vitesse"),
    // immatriculation: yup.string().required("Veuillez saisir une immatriculation"),
    clientEmail: yup.string().required("Veuillez saisir une adresse e-mail").email("Veuillez saisir une adresse e-mail valide"),
    reservationExtern: yup.string().required("Veuillez saisir un numéro de réservation"),
    reservationPrice: yup.number().typeError("Veuillez saisir un nombre").required("Veuillez saisir un prix de location"),
    reservationPaidAmount: yup.number().typeError("Veuillez saisir un nombre").required("Veuillez saisir un montant d'acompte"),
    reservationAmountToBePaid: yup.number(),
    reservationTotalToBePaid: yup.number(),
    })
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
    watch,
    setValue,
    getValues,
  } = useForm<IFormValue>({
    resolver: schemaResolver,
    defaultValues: reservation !== null ? {
       startDateTime: new Date(defaultStartDate),
    } : {
      // startDateTime: new Date(defaultStartDate),
    }
  });

  const pad = (value: any) => {
    return value < 10 ? '0' + value : value;
  }

  const createOffset = (date: any) => {
    var sign = (date.getTimezoneOffset() > 0) ? -1 : +1;
    var offset = Math.abs(date.getTimezoneOffset());
    var hours = pad(Math.floor(offset / 60));
    var minutes = pad(offset % 60);
    return sign * hours;
  }

  const getDateByTimeZone = (date: any) => {
    return date.setHours(date.getHours() + createOffset(date));
  }

// Fonction pour convertir une chaîne de caractères de date en format "jj/mm/yyyy h:i"
const formatDateString = (dateString: string | number | Date) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    timeZone: 'UTC', // Indique que le fuseau horaire doit être UTC (ajustez si nécessaire)
  };

  const formattedDate = new Date(dateString).toLocaleDateString('fr-FR', options as any);
  const formattedTime = new Date(dateString).toLocaleTimeString('fr-FR', options as any);


  return `${formattedDate} `;
};


  const onSubmit = useCallback((data) => {

    let startAt = toISOLocal(new Date(data.startDateTime)).split("T");
    let time = startAt[1].split(":")[0]+':'+startAt[1].split(":")[1];
    let endAt = toISOLocal(new Date(data.endDateTime)).split("T");
    let endTime = endAt[1].split(":")[0]+':'+endAt[1].split(":")[1];
    let formatStartAt = reservation && reservation?.startAt == data.startDateTime ? 
                                                new Date(startAt[0]+" "+time) : 
                                                new Date(getDateByTimeZone(new Date(startAt[0]+" "+time)));
    let formatEndAt = reservation && reservation?.endAt == data.endDateTime ? 
                                              new Date(endAt[0]+" "+endTime) : 
                                              new Date(getDateByTimeZone(new Date(endAt[0]+" "+endTime)));
    // new Date(getDateByTimeZone(new Date(endAt[0]+" "+endTime)));
    data.startDateTime = formatStartAt;
    data.endDateTime = formatEndAt;

    const dataToSendToWire = ({
      ...data,
      reservationTotalToBePaid: +reservationNetToPay + Number(data.reservationPrice),
      reservationAmountToBePaid: (+reservationNetToPay + Number(data.reservationPrice)) - Number(data.reservationPaidAmount),
      status: "WAITING_PAYMENT",
      source: "Rentile",
    })

    if (reservation) {
      console.log('this réservation : ', reservation)
      // Update reservation
      dispatch(updateReservationExterne(reservation._id, {
        startDateTime: dataToSendToWire.startDateTime || reservation.startAt,
        endDateTime: dataToSendToWire.endDateTime || reservation.endAt as any as string,
        // station: dataToSendToWire.station || reservation.vehicleInstance?.stationId,
        vehicle: dataToSendToWire.vehicle || reservation?.vehicleInstance?.vehicleId,
        clientEmail: dataToSendToWire.clientEmail || reservation?.clientEmail,
        reservationExtern: dataToSendToWire.reservationExtern || reservation?.reservationExtern,
        gearbox: dataToSendToWire.gearbox || reservation?.vehicleInstance?.vehicle?.gearbox,
        immatriculation: dataToSendToWire.immatriculation || reservation?.vehicleInstance?.immatriculation,
        source: dataToSendToWire.source || reservation?.source,
        reservationStatus: dataToSendToWire.reservationStatus || reservation?.reservationStatus,
        reservationPrice: dataToSendToWire.reservationPrice || reservation?.reservationPrice,
        reservationPaidAmount: dataToSendToWire.reservationPaidAmount || reservation?.reservationPaidAmount,
        reservationTotalToBePaid: dataToSendToWire.reservationTotalToBePaid || reservation?.reservationTotalToBePaid,
      }))
    } else {
      dispatch(createReservationThunk(dataToSendToWire, reset))
    }

  }, [dispatch, reservation, reservationNetToPay, reset]);

  useEffect(() => {
    if (!stations.length) {
      setReservationNetToPay(0)
      dispatch(loadStationsThunk())
    }
  }, [dispatch, stations])

  useEffect(() => {
    let startAt = toISOLocal(new Date(startDateTime)).split("T");
    let time = startAt[1].split(":")[0]+':'+startAt[1].split(":")[1];
    let endAt = toISOLocal(new Date(endDateTime)).split("T");
    let endTime = endAt[1].split(":")[0]+':'+endAt[1].split(":")[1];
    let formatStartAt = new Date(startAt[0]+" "+time);
    console.log('gearbox3', reservation?.vehicleInstance?.vehicle?.gearbox)
    reset({
    

      startDateTime: formatStartAt,
      endDateTime: reservation?.endAt as any as string,
      vehicle: reservation?.vehicleInstance?.vehicle?._id,
      clientEmail: reservation?.clientEmail,
      reservationExtern: reservation?.reservationExtern,
      station: reservation?.vehicleInstance?.stationId,
      gearbox: reservation?.vehicleInstance?.vehicle?.gearbox,
      immatriculation: reservation?.vehicleInstance?.immatriculation,
      source: reservation?.source,
      reservationStatus: reservation?.reservationStatus,
      reservationPrice: reservation?.reservationPrice,
      reservationPaidAmount: reservation?.reservationPaidAmount,
      reservationTotalToBePaid: reservation?.reservationTotalToBePaid,
      reservationAmountToBePaid: reservation?.reservationAmountToBePaid,
    });
  }, [reset, reservation]);

  useEffect(() => {
    // Au chargement du composant, mettez à jour les champs d'entrée avec les valeurs existantes de la réservation
    if (reservation) {
      setValue('reservationAmountToBePaid', reservation.reservationAmountToBePaid);
      // Mettez à jour d'autres champs si nécessaire
    }
  }, [reservation, setValue]);
  
  useEffect(() => {
    // À chaque modification de la réservation, mettez à jour les champs d'entrée avec les nouvelles valeurs calculées
    if (reservation) {
      // Calculez la nouvelle valeur de reservationAmountToBePaid
      const newReservationTotalToBePaid = (+reservationNetToPay + Number(getValues('reservationPrice')));
      const difference = newReservationTotalToBePaid - Number(getValues('reservationPaidAmount'));
      setValue('reservationAmountToBePaid', difference);
      // Mettez à jour d'autres champs si nécessaire
    }
  }, [reservation, reservationNetToPay, getValues, setValue]);
  
  useEffect(() => {
    const zero = 0;
    const subscribe = watch((data, { name, type }) => {
      if (data.station && data.startDateTime && data.endDateTime) {
        setReservationNetToPay(zero)
        dispatch(loadVehicleAvailblePerStation({ station: data.station as string, from: (data.startDateTime as any as string), to: (data.endDateTime as string) }))
      }

      if(reservation){
        if (reservation?.vehicleInstance?.stationId && reservation?.startAt && reservation?.endAt) {
          setReservationNetToPay(zero)
          dispatch(loadVehicleAvailblePerStation({ station: reservation?.vehicleInstance?.stationId as string, from: (reservation?.startAt as any as string), to: (reservation?.endAt as any as string) }))
          dispatch(loadGearBoxes({ name: reservation?.vehicleInstance?.vehicle?.name, brand: reservation?.vehicleInstance?.vehicle?.brand, station: data?.station }))
          dispatch(loadVehicleImmatriculation({ name: reservation?.vehicleInstance?.vehicle?.name, brand: reservation?.vehicleInstance?.vehicle?.brand, gearbox: reservation?.vehicleInstance?.vehicle.gearbox, station: reservation?.vehicleInstance?.stationId, from: reservation?.startAt as any as string, to: (reservation?.endAt as any as string) }))

          console.log('vehicle im', listImmatriculations)
        }
      }

      if (data.station) {
        dispatch(loadOptionsBasedStationThunk(data.station as string))
        setReservationNetToPay(zero)
      }

      if ((data.vehicle) && !data.gearbox) {
        const  vehicle  = (availableVehiclesPerStation).find(vehicleInstance => (vehicleInstance?._id === (data.vehicle))) as any
        dispatch(loadGearBoxes({ name: vehicle?.name, brand: vehicle?.brand, station: data?.station }))
      }

      if (data.gearbox && data.vehicle && !data.immatriculation) {
        const  vehicle  = (availableVehiclesPerStation).find(vehicleInstance => (vehicleInstance?._id === (data.vehicle))) as any
        dispatch(loadVehicleImmatriculation({ name: vehicle?.name, brand: vehicle?.brand, gearbox: data.gearbox, station: data?.station, from: data?.startDateTime as any as string, to: data?.endDateTime }))
      }

      if (data.reservationPrice && reservationNetToPay) {
        setReservationTotalToBePaid(+reservationNetToPay + +(data.reservationPrice || 0));
        const roundedAmountToBePaid = (+reservationTotalToBePaid - +(data.reservationPaidAmount || 0)).toFixed(2);
        setReservationAmountToBePaid(parseFloat(roundedAmountToBePaid))
      }

      if (data.reservationPrice && reservation) {
        setReservationTotalToBePaid(+reservationNetToPay + +(data.reservationPrice || 0));
        const roundedAmountToBePaid = (+reservationTotalToBePaid - +(reservation?.reservationPaidAmount ||data.reservationPaidAmount || 0)).toFixed(2);
        setReservationAmountToBePaid(parseFloat(roundedAmountToBePaid))
      }
      

      if (data.reservationPaidAmount) {
        setReservationTotalToBePaid(+reservationNetToPay + +(data.reservationPrice || 0));
        const roundedAmountToBePaid = (+reservationTotalToBePaid - +(data.reservationPaidAmount || 0)).toFixed(2);
        setReservationAmountToBePaid(parseFloat(roundedAmountToBePaid));
      }
    })

    return () => subscribe.unsubscribe()
  }, [availableVehiclesPerStation, dispatch, optionsBasedStation, optionsBasedStation.length, reservationNetToPay, reservationTotalToBePaid, setValue, watch])

    // Add this useEffect block to update reservationNetToPay when the station changes
    useEffect(() => {
      // Reset reservationNetToPay to 0 when the station changes
      setReservationNetToPay(0);
        setValue('gearbox', '')
        setValue('immatriculation', '')
    }, [watch('station')]); // Trigger the effect when the station value changes

    useEffect(() => {
      setValue('gearbox', '')
    }, [watch('vehicle')]);

    useEffect(() => {
      setValue('immatriculation', '')
    }, [watch('gearbox')]);


    let a = reservation?.startAt;

const formattedDateTimeStart = formatDateString(reservation?.startAt as any);

const formattedDateTimeEnd = formatDateString(reservation?.endAt as any);
    let startAt = toISOLocal(new Date(reservation?.startAt as any as string)).split("T");
    let time = startAt[1].split(":")[0]+':'+startAt[1].split(":")[1];
    let endAt = toISOLocal(new Date(reservation?.endAt as any as string)).split("T");
    let endTime = endAt[1].split(":")[0]+':'+endAt[1].split(":")[1];
    let formatStartAt = new Date(getDateByTimeZone(new Date(startAt[0]+" "+time)));
    let formatEndAt = new Date(getDateByTimeZone(new Date(endAt[0]+" "+endTime)));
    let startDateTime = formatStartAt;
    let endDateTime = formatEndAt;
  // Obtenez les composants de la date
const newDateStart= new Date(startDateTime as any as string) ;
const dayStart = newDateStart.getDate();
const month0Start = newDateStart.getMonth();
const monthStart = month0Start ? month0Start + 1: 0// Les mois commencent à partir de zéro
const yearStart = newDateStart.getFullYear();
const hoursStart = newDateStart.getHours();
const minutesStart = newDateStart.getMinutes();
const secondsStart = newDateStart.getSeconds();

// Formatez la date au format 'dd/MM/yyyy HH:mm:ss'
const formattedDateStart = `${dayStart}/${monthStart}/${yearStart} ${hoursStart}:${minutesStart}`;

const formattedDateStart0 = `${yearStart}/${monthStart}/${dayStart} ${hoursStart}:${minutesStart}`;


// Obtenez les composants de la date
const newDateEnd= new Date(endDateTime as any as string) ;
const dayEnd = newDateEnd.getDate();
const month0End = newDateEnd.getMonth();
const monthEnd = month0End ? month0End + 1: 0// Les mois commencent à partir de zéro
const yearEnd = newDateEnd.getFullYear();
const hoursEnd = newDateEnd.getHours();
const minutesEnd = newDateEnd.getMinutes();
const secondsEnd = newDateEnd.getSeconds();

// Formatez la date au format 'dd/MM/yyyy HH:mm:ss'
const formattedDateEnd = `${dayEnd}/${monthEnd}/${yearEnd} ${hoursEnd}:${minutesEnd}`;



  return (
    <Modal size="xl" show={show} onHide={handleClose}>
      <Modal.Header onHide={handleClose} closeButton>
        <Modal.Title as="h3">
        {reservation ? 'Modifier la ' : 'Ajouter une nouvelle'} réservation
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(onSubmit as any ) } className="authentication-form" noValidate>
          <Col>
            <h4 className="text-md text-primary">Détails de la reservation</h4>
            {dateAreSet ? 
            <Row>
              <Col xs={'3'}>
              <FormInput
  register={register}
  control={control}
  errors={errors}
  type="datetime-local"
  name="startDateTime"
  label={'Date et heure de début'}
  containerClass={!reservation ? 'mb-3' : ''}
  required={true}
  defaultValue={defaultStartDate}
/>
     
                {reservation?.startAt && <label className="text-sm mb-3 d-flex justify-content-center">{formattedDateTimeStart}</label> }
              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="datetime-local"
                  name="endDateTime"
                  label={'Date et heure de fin'}
                  containerClass={!reservation ? 'mb-3' : ''}
                  required = {true}
                  // minDate={formattedDateStart0 as any as Date}
                  min={newDateStart.getTime()}
                />
                {reservation?.endAt && <label className="text-sm mb-3 d-flex justify-content-center">{formattedDateTimeEnd}</label> }
              </Col>
              <Col xs={"5"} className='ml-auto' style={ { 'marginLeft': 'auto', } }>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="station"
                  label={'Station de départ et de retour'}
                  containerClass={'mb-3'}
                  required = {true}
                >
                  <option value="">Selectionner une station</option>
                  { stations.map(station => <option key={'station-' + station._id} value={station._id}>{ station.name }</option> )}
                </FormInput>
                {reservation?.clientEmail && <label className="text-sm mb-3 d-flex justify-content-center">{reservation?.station?.name}</label> }

              </Col>
            </Row>
            : ""
            }
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="vehicle"
                  label={'Véhicule'}
                  containerClass={!reservation ? 'mb-3' : ''}
                  required = {true}
                >
                  <option value="">Selectionner un vehicule</option>
                  { availableVehiclesPerStation?.map(vehicleInstance => <option key={'vehicle-' + vehicleInstance?._id} value={vehicleInstance?._id}  defaultValue={
        reservation
        //  &&
        // reservation.vehicleInstance?.vehicle?._id === vehicleInstance?._id
          ? reservation.vehicleInstance?.vehicle?._id
          : ''
      }>{vehicleInstance?.name + '-' + vehicleInstance?.brand }</option> ) }
                </FormInput>
                {reservation?.vehicleInstance?.vehicleId  && <label className="text-sm mb-3 d-flex justify-content-center">{ reservation.vehicleInstance?.vehicle.name + '-' +  reservation.vehicleInstance?.vehicle?.brand}</label> }
              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="gearbox"
                  label={'Boite de vitesse'}
                  containerClass={!reservation ? 'mb-3' : ''}
                  required = {true}
                  defaultValue={ reservation?.vehicle?.gearbox ? reservation?.vehicle?.gearbox : ''}
                >
                  <option value={ reservation?.vehicleInstance?.vehicle?.gearbox ? reservation?.vehicleInstance?.vehicle?.gearbox : ''} defaultValue={reservation ? reservation.vehicleInstance?.vehicle.gearbox : ''}>Selectionner la boite de vitesse</option>
                  { listGearBoxes.map((gearbox, idx) => <option key={idx + '-' + gearbox} value={gearbox}>{gearbox}</option> ) }
                </FormInput>
                {reservation && <label className="text-sm mb-3 d-flex justify-content-center">{ reservation.vehicleInstance?.vehicle.gearbox }</label> }
              </Col>
              <Col xs={"5"} className='ml-auto' style={ { 'marginLeft': 'auto', } }>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="immatriculation"
                  label={'Immatriculation'}
                  containerClass={!reservation ? 'mb-3' : ''}
                  required = {true}
                >
                  <option value="">Pas d'immatriculation</option>
                  { listImmatriculations.map((immatriculation, idx) => <option key={`immatriculation-${idx}-${immatriculation}`} value={immatriculation}>{immatriculation}</option> ) }
                </FormInput>
                {reservation && <label className="text-sm mb-3 d-flex justify-content-center">{ reservation.vehicleInstance?.immatriculation }</label> }
              </Col>
            </Row>
          </Col>
          <Col>
            <h4 className="text-md text-primary">Client</h4>
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  name="clientEmail"
                  label={'Adresse mail'}
                  containerClass={'mb-3'}
                  required = {true}
                />
              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  name="reservationExtern"
                  label={'Numéro de réservation'}
                  containerClass={'mb-3'}
                  required = {true}
                />
              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  name="source"
                  label={'Source'}
                  containerClass={'mb-3'}
                  value={"Rentile"}
                  disabled={true}
                />
              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="select"
                  name="reservationStatus"
                  label={'Status'}
                  containerClass={'mb-3'}
                  disabled={true}
                >
                  <option value="">Selectionner le status de la reservation</option>
                  <option value="PAYMENT_DONE">Paiement effectué</option>
                  <option value="VEHICLE_TAKEN">Validée</option>
                  <option value="IN_PROGRESS">En cours</option>
                  <option value="WAITING_PAYMENT" selected>En attente de paiement</option>
                  <option value="PAYMENT_INITIATED">En attente</option>
                  <option value="CLOSED">Terminée</option>
                  <option value="CANCELED">Annulée</option>
                </FormInput>
              </Col>
            </Row>
          </Col>

          <Col>
            <h4 className="text-md text-primary">Paiement</h4>
            <Row>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="number"
                  name="reservationPrice"
                  label={'Prix de la location'}
                  containerClass={'mb-3'}
                />
                <label className="text-sm mb-3 d-flex justify-content-center">{reservation?.reservationPrice ? "Ancien Prix de location: " : ""}{reservation?.reservationPrice}</label>

              </Col>
              <Col xs={'3'}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="number"
                  name="reservationPaidAmount"
                  label={'Accompte versé'}
                  containerClass={'mb-3'}
                />
                <label className="text-sm mb-3 d-flex justify-content-center">{reservation?.reservationPaidAmount ? "Ancien acompte à verser: " : ""}{reservation?.reservationPaidAmount}</label>

              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="number"
                  value={reservationAmountToBePaid}
                  name="reservationAmountToBePaid"
                  label={'Reste à payer'}
                  disabled={true}
                  containerClass={'mb-3'}
                />
                <label className="text-sm mb-3 d-flex justify-content-center">
                  { reservation?.reservationAmountToBePaid ? ((["WAITING_PAYMENT", "PAYMENT_INITIATED"]).includes(reservation?.status as string) ? "Reste à payer par le client: ": " Montant déjà payé par le client: ") : ""}{reservation?.reservationAmountToBePaid}</label>

              </Col>
              <Col xs={"3"}>
                <FormInput
                  register={register}
                  control={control}
                  errors={errors}
                  type="text"
                  value={reservationTotalToBePaid}
                  name="reservationTotalToBePaid"
                  label={'Montant total options incluse'}
                  disabled={true}
                  containerClass={'mb-3'}
                />
                <label className="text-sm mb-3 d-flex justify-content-center">
                {reservation?.reservationTotalToBePaid ? "Montant total: " : ""}{reservation?.reservationTotalToBePaid}</label>

              </Col>
            </Row>
          </Col>

          <div className="mb-3 text-center d-flex flex-row-reverse ms-auto gap-2">
            <Button type="submit" variant='primary'
              disabled={isLoading || isSubmitting}
            >
              {(isLoading || isSubmitting) ? (
                <div className="spinner">
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              ) : (
                <>
                  {reservation ? "Modifier" : "Ajouter"}
                </>
              )}
            </Button>
            <Button variant="light" onClick={handleClose}>
              Annuler
            </Button>{' '}
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
