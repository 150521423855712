import { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import PageTitle from '../../components/PageTitle';
import Table from '../../components/Table';
import { setCancelReservationModal, setEtas, setValidateReservationModal, setOpenReservationEditionModal, setOpenContraventionEditionModal, setOpenRemboursementEditionModal, setReservation } from '../../redux/reservations/actions';
import { ReservationsState } from '../../redux/reservations/reducers';
import { loadReservationThunk, loadReservationsHistoryThunk, reservationEtats, updateReservation } from '../../redux/reservations/thunk';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Reservation, ReservationHistory, VehicleInstance } from '../../types';
import { formatDateLocal, toISOLocal } from '../../utils/date';
import { formatCurrency } from "../../utils/number";
import { etaStatusClass, EtatStatusLabel, reservationsStatusLabel, reservationStatusClass } from '../../utils/reservation';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { getAvailableVehicleInstanceThunk } from '../../redux/vehicleInstance/thunk';
import { VehicleInstanceState } from '../../redux/vehicleInstance/reducers';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput } from '../../components';
import { Loader } from 'react-bootstrap-typeahead';
import CreateFormDialog from './CreateFormDialog';
import ContraventionDialog from './ContraventionDialog';
import RemboursementDialog from './RemboursementDialog';
import { colors } from 'react-select/dist/declarations/src/theme';
// import DetailsDialog from './DetailsDialog';


const sizePerPageList = [
  {
    text: '5',
    value: 5,
  },
  {
    text: '10',
    value: 10,
  },
  {
    text: '25',
    value: 25,
  },
  {
    text: '50',
    value: 50,
  }
];
interface Props {
  reservation: Reservation;
}
const ReservationOne = () => {
  const { reservationId } = useParams<{ reservationId: string }>();
  const dispatch = useAppDispatch();
  const { reservation, showNewAndEditReservationModal, showNewAndEditContraventionModal, showNewAndEditRemboursementModal  } = useAppSelector(({ Reservations }) => Reservations) as ReservationsState;
  // const [reservation, setReservation] = useState<Reservation | undefined>(undefined);
  const [showDetailsDialog, setShowDetailsDialog] = useState<boolean>(false);
  const handleDetailShow = useCallback(() => setShowDetailsDialog(true), [setShowDetailsDialog])
  const handleDetailClose = useCallback(() => setShowDetailsDialog(false), [setShowDetailsDialog])


  const handleOpenCloseReservationEdition = useCallback((payload: boolean) =>
    () => dispatch(setOpenReservationEditionModal(payload)), [dispatch])
    
    const handleOpenCloseContraventionEdition = useCallback((payload: boolean) =>
    () => dispatch(setOpenContraventionEditionModal(payload)), [dispatch])

    const handleOpenCloseRemboursementEdition = useCallback((payload: boolean) =>
    () => dispatch(setOpenRemboursementEditionModal(payload)), [dispatch])

  useEffect(() => {
    // if (!reservation) {
      // Utilisez la nouvelle fonction pour charger l'historique des réservations
      dispatch(loadReservationThunk(reservationId));
    // }
  }, [dispatch, reservationId]);
  const getReservationDateUTC = (date: any) => {
    const reservationDate = new Date(date);
    return reservationDate.setHours(reservationDate.getHours() - createOffset(reservationDate));
  }

  
  const { isUpdateLoading, etats } = useAppSelector(({ Reservations }) => Reservations) as ReservationsState;

  const { vehicleInstancesImmat } = useAppSelector(({ vehicleInstances }) => vehicleInstances) as VehicleInstanceState;

  // const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAvailableVehicleInstanceThunk(reservation?.station?._id, reservation?.startAt.toString() as string, reservation?.endAt.toString() as string));
    dispatch(reservationEtats(reservationId as string));
  }, [dispatch]);

  // const reservationStartDate = new Date(reservation?.startAt as Date);
  // const reservationEndDate = new Date(reservation?.endAt as Date);
  const reservationStartDate = reservation?.startAt ? new Date(reservation.startAt) : null;
const reservationEndDate = reservation?.endAt ? new Date(reservation.endAt) : null;


  const pad = (value: any) => {
    return value < 10 ? '0' + value : value;
  }
  const createOffset = (date: any) => {
    var sign = (date.getTimezoneOffset() > 0) ? -1 : +1;
    var offset = Math.abs(date.getTimezoneOffset());
    var hours = pad(Math.floor(offset / 60));
    var minutes = pad(offset % 60);
    return sign * hours;
  }

  const spiteISODate = (date: Date) => {
    // return date.toString().split("T")[0];
    if (date) {
      return date.toString().split("T")[0];
    } else {
      return ""; // ou gérez-le en fonction de vos besoins
    }
  }

  const spiteISOTime = (date: Date) => {
    // const longTime = date.toString().split("T")[1];
    const longTime = date?.toString().split("T")[1] || '';

    const time = longTime.split(":");
    // return `${time[0]}:${time[1]}`;
    if (date) {
      return `${time[0]}:${time[1]}`;
    } else {
      return ""; // ou gérez-le en fonction de vos besoins
    }
  }

  const getDateByTimeZone = (date: any) => {
    return date.setHours(date.getHours() + createOffset(date));
  }

  // const reservationStartDateUTC = reservationStartDate.setHours(reservationStartDate.getHours() - createOffset(reservationStartDate));
  // const reservationEndDateUTC = reservationStartDate.setHours(reservationEndDate.getHours() - createOffset(reservationEndDate));
  const reservationStartDateUTC = reservationStartDate ? reservationStartDate.setHours(reservationStartDate.getHours() - createOffset(reservationStartDate)) : null;
const reservationEndDateUTC = reservationEndDate ? reservationEndDate.setHours(reservationEndDate.getHours() - createOffset(reservationEndDate)) : null;


  const onSubmit = useCallback((data) => {
    let startAt = toISOLocal(data.debut).split("T");
    let time = startAt[1].split(":")[0]+':'+startAt[1].split(":")[1];
    let endAt = toISOLocal(data.fin).split("T");
    let endTime = endAt[1].split(":")[0]+':'+endAt[1].split(":")[1];
    let formatStartAt = new Date(getDateByTimeZone(new Date(startAt[0]+" "+time)));
    let formatEndAt = new Date(getDateByTimeZone(new Date(endAt[0]+" "+endTime)));
    data.debut = formatStartAt;
    data.fin = formatEndAt;
    dispatch(updateReservation(reservationId as string, data));
  }, [dispatch]);

  const schemaResolver = yupResolver(
    yup.object().shape({
      immatriculation: yup.string().typeError("Veuillez choisir une immatriculation").required("requis"),
      debut: yup.date().typeError("Veuillez sélectionner une date de départ").required("requis"),
      fin: yup.date().typeError("Veuillez sélectionner une date de retour").required("requis"),
      status: yup.string().typeError("Veuillez choisir un status").required("requis"),
    })
  );

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<{
    immatriculation: string | null;
    debut: Date | null;
    fin: Date | null;
    unlockVehicule: boolean;
    status: string;
  }>({
    resolver: schemaResolver,
    defaultValues: reservation?.vehicle !== null ? {
      immatriculation: reservation?.vehicleInstance?.immatriculation,
      debut: reservation?.startAt,
      fin: reservation?.endAt,
      unlockVehicule: reservation?.unlockVehicule,
      status: reservation?.status
    } : {}
  });

  /**
   * Use Form hooks.
   */
  useEffect(() => {
    reset({});
  }, [reset, reservation]);

  function formatDateOfBirth(date: string | number | Date) {
    if (!date) {
      return '-';
    }
  
    const formattedDate = new Date(date);
    const day = formattedDate.getDate().toString().padStart(2, '0');
    const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0'); // Les mois commencent à 0
    const year = formattedDate.getFullYear();
  
    return `${day}/${month}/${year}`;
  }

  // const formattedBirthDate = formatDateOfBirth(reservation?.user?.birthDate);

  return (
    <div style={{ backgroundColor: 'white', marginTop: '20px'  }} className='card'>
    <div style={{ marginLeft: '60px', marginRight: '60px', marginTop: '20px !important', marginBottom: '20px', paddingBottom:'20px', paddingTop:'20px !important' }}>
      <h4 style={{ marginTop: '20px', paddingTop:'20px' }}>
          XXXDetails de la reservation Nº {reservation?.reservationNumber}
        </h4>
      <div className="d-flex gap-2 border-bottom mb-2">
        <div
          className="rounded-circle border border-primary mb-1 border-bottom"
          style={{
            width: "60px",
            height: "60px",
            borderRadius: "50%",
            overflow: 'hidden'
          }}>
          {reservation?.user.photoUrl ? (
            <img style={{
              objectFit: 'cover',
              backgroundPosition: 'center',
              backgroundSize: "cover",
              width: "100%",
              height: "100%",
            }} src={reservation?.user.photoUrl} alt={reservation?.user.name} />
          ) : (
            <div
              className="bg-primary border-2 border-secondary d-flex align-items-center justify-content-center"
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "50%",
              }}
            >
              <h5 className="fw-bold text-white">
                {reservation?.user.name[0] + "." + reservation?.user.firstName[0]}
              </h5>
            </div>
          )}
        </div>
        <div className="d-flex flex-column gap-1">
          <div className="d-flex gap-1">
            <span className="fw-bold">{reservation?.user.name}</span>
            <span className="fw-bold">{reservation?.user.firstName}</span>
          </div>
          

          <span>{reservation?.user.email}</span>
          {reservation?.user.isVerified ? <span style={{color:'green'}}>Profil vérifié</span> :  <span style={{color:'red'}}>Profil non vérifié</span>}
          {/* <span>{reservation?.user.isVerified ? 'Profil vérifié' : 'Profil non vérifié'}</span> */}


            
            
        </div>
      </div>
      <h1 className="h6">Réservation </h1>
      {
        reservation?.vehicleInstance
        ?
        <span className="fw-bold">{`${reservation?.vehicleInstance?.vehicle?.name} ${reservation?.vehicleInstance?.vehicle?.brand} - ${reservation?.vehicleInstance?.immatriculation}`}</span>
        :
        <span className="fw-bold">{`${reservation?.vehicle?.name} ${reservation?.vehicle?.brand} - "Pas d'imatriculation attribuée"`}</span>

      }
      {/* <span className="fw-bold">{`${reservation?.vehicleInstance?.vehicle?.name} ${reservation?.vehicleInstance?.vehicle?.brand} - ${reservation?.vehicleInstance?.immatriculation}`}</span> */}
      <div className="d-flex gap-2">
        <div
          className="mb-3 border"
          style={{
            width: "350px",
            height: "auto",
            padding: '5px',
            overflow: 'hidden', 
          }}
        >
          <img
            style={{
              width: "100%",
              height: "150px",
              objectFit: 'cover',
              objectPosition: 'center',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
            }}
            src={reservation?.vehicleInstance?.vehicle.absolutePhotoUrl}
            alt={`reservation ${reservation?.vehicleInstance?.vehicle.name}`}
          />
          <h4>Locataire</h4>
          <div className="d-flex gap-1 mt-2">
            <span>Nom: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user.firstName} {reservation?.user.name}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Date de naissance: </span>
            <span style={{fontWeight: 'bold'}}>
            
            
            {reservation?.user?.birthDate ? formatDateOfBirth(reservation?.user?.birthDate)  : '-'}
              {/* {reservation?.user?.birthDate ? formatDateLocal(spiteISODate(reservation?.user?.birthDate as Date))  : '-'} */}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Adresse: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user.address}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Code postal: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user.postalCode}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Ville: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user.city}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Téléphone: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user.phone}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Email: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user.email}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Permis Nº: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user.permisNumber ? reservation?.user.permisNumber : '-'}
            </span>
          </div>
          <div className="d-flex gap-1 mt-2">
            <span>Date d'obtention: </span>
            <span style={{fontWeight: 'bold'}}>
              {reservation?.user?.obtainedAt ? (new Date(reservation?.user?.obtainedAt)).toLocaleDateString('fr') : '-'}
            </span>
          </div>

          { reservation?.secondUser && (
              <div className="mt-3">
                <h5>Locataire secondaire</h5>
                <div className="d-flex gap-1 mt-3">
                  <span>Nom: </span>
                  <span style={{fontWeight: 'bold'}}>
                    {reservation?.secondUser.firstName} {reservation?.secondUser.name}
                  </span>
                </div>
                <div className="d-flex gap-1 mt-2">
                  <span>Email: </span>
                  <span style={{fontWeight: 'bold'}}>
                    {reservation?.secondUser.email}
                  </span>
                </div>
              </div>
            ) 
          }

        </div>
        <div className="flex-grow-1 d-flex flex-column gap-1">
          <div className="d-flex gap-2">
            <form onSubmit={handleSubmit(onSubmit)} className="authentication-form" noValidate>
              <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="select"
                    name="immatriculation"
                    placeholder="Immatriculation"
                    label={'Immatriculation'}
                    containerClass={'mb-1'}
                  >
                    <option disabled>Selectionner</option>
                    <option key={reservation?.vehicleInstance?._id} value={reservation?.vehicleInstance?.immatriculation} selected>{reservation?.vehicleInstance?.immatriculation}</option>
                    {vehicleInstancesImmat?.map((c: VehicleInstance) => {
                      if(c) {
                        return (
                          c.immatriculation == reservation?.vehicleInstance?.immatriculation ?
                            <option key={c._id} value={c.immatriculation} selected>{c.immatriculation}</option>
                          :
                            <option key={c._id} value={c.immatriculation}>{c.immatriculation}</option>
                        )
                      }
                    })}
                  </FormInput>
                </Col>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="select"
                    name="status"
                    placeholder="Status"
                    label={'Status'}
                    containerClass={'mb-1'}
                    id="state"
                    defaultValue={reservation?.status}
                  >
                    <option disabled>Selectionner</option>
                    <option value="CANCELED">Annulée</option>
                    <option value="CLOSED" >Terminée</option>
                    <option value="VEHICLE_TAKEN">Validée</option>
                    <option value="IN_PROGRESS">En cours</option>
                    <option value="WAITING_PAYMENT">En attente de paiement</option>
                    <option value="PAYMENT_INITIATED">En attente</option>
                    <option value="PAYMENT_DONE">Paiement effectué</option>
                  </FormInput>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="datetime-local"
                    name="debut"
                    placeholder="Début"
                    label={'Début'}
                    containerClass={'mb-1'}
                    defaultValue={reservation?.startAt.toString().split(".")[0]}
                  />
                </Col>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="datetime-local"
                    name="fin"
                    placeholder="Fin"
                    label={'Fin'}
                    containerClass={'mb-1'}
                    defaultValue={reservation?.endAt.toString().split(".")[0]}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormInput
                    register={register}
                    control={control}
                    errors={errors}
                    type="checkbox"
                    name="unlockVehicule"
                    label={'Voiture déverrouillée'}
                    containerClass={'mb-1 mt-2'}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <button
                    className="btn btn-primary rounded-3 d-flex gap-2 fw-bold text-uppercase mt-2 mb-2"
                  >
                    {isUpdateLoading ? (
                      <Loader />
                    ) : (
                      <>
                        Sauvegarder
                      </>
                    )}
                  </button>
                </Col>
              </Row>
            </form>
          </div>
          {reservation?.clientEmail ?
          <>
          <div className="d-flex gap-2">
            <span><i className="bi bi-hash h4"></i></span>
            <span className="">
            N° de réservation externe
              {" : "}
              {reservation?.reservationExtern}
            </span>
          </div>
          </>
          :
          ''
          }
          
          <div className="d-flex gap-2">
            <span><i className="bi bi-geo-alt-fill h4"></i></span>
            <span className="">
              Station
              {" : "}
              {reservation?.vehicleInstance?.station?.name}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span><i className="bi bi-calendar2-day h4"></i></span>
            <span className="">
              {formatDateLocal(spiteISODate(reservation?.startAt as Date))} {"à"} {spiteISOTime(reservation?.startAt as Date)}
              {" - "} {formatDateLocal(spiteISODate(reservation?.endAt as Date))} {"à"} {spiteISOTime(reservation?.endAt as Date)}
            </span>
          </div>
          <div className="d-flex gap-2">
            <span><i className="bi bi-shield-plus h4"></i></span>
            <span className="">
              {reservation?.insuranceForfait.name}
              {" : "}
              <span>{formatCurrency(reservation?.insuranceForfait.price as number)}</span>
            </span>
          </div>
          
          <div className="d-flex gap-2">
            <span><i className="bi bi-signpost h4"></i></span>
            <span className="">
              Kilometres inclus
              {" : "}
              {reservation?.clientEmail ? "illimité" : reservation?.kmForfait.includedKm + " Km"} 
            </span>
          </div>
          <div className="d-flex gap-2">
            <span><i className="bi bi-signpost h4"></i></span>
            <span className="">
              Kilometres additionels
              {" : "}
              {reservation?.additionalKm} Km
            </span>
          </div>

          <div className="d-flex gap-2">
            <span><i className="bi bi-signpost h4"></i></span>
            <span className="">
              prix journalier
              {" : "}
              {formatCurrency(reservation?.vehicleInstance?.vehicle.dailyPrice as number)}
            </span>
          </div>
            
          { reservation?.option?.length as number > 0 && <strong className="fw-bold mt-2 mb-2">Options : </strong> }
          
          {
            reservation?.option?.map((option, index) => (
              <div className="d-flex gap-2">
                <span><i className="bi bi-tag h4"></i></span>
                <span className="">
                  {option.name}
                  {" - "}
                  {formatCurrency(option.price)}
                </span>
              </div>
            ))
          }


          <div className="d-flex justify-content-between pt-1 border-top">
            <strong className="fw-bold h4">Total : </strong>
            <span className="fw-bold h4">
              {reservation?.clientEmail ? formatCurrency(reservation?.reservationTotalToBePaid as any) : formatCurrency(reservation?.amount as number)}
            </span>
          </div>

          {reservation?.clientEmail ?
          <>
          <div className="d-flex justify-content-between pt-1 border-top">
          <strong className="fw-bold h4">Reste à payer : </strong>
          <span className="fw-bold h4">
            {formatCurrency(reservation?.amount)}
          </span>
        </div>
          
        </>
          :
          ''
          }

          {
            reservation?.kmStarter ?
              <div className="d-flex gap-2 pt-1 border-top">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Kilometres début
                  {" - "}
                  {reservation?.kmStarter} Km
                </span>
              </div>
            : null
          }

          {
            reservation?.kmStarter ?
              <div className="d-flex gap-2">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Kilometres fin
                  {" - "}
                  {reservation?.kmEnded} Km
                </span>
              </div>
            : null
          }

          {
            reservation?.kmStarter ?
              <div className="d-flex gap-2">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Carburant début
                  {" - "}
                  {reservation?.carburantStarter}%
                </span>
              </div>
            : null
          }

          {
            reservation?.kmStarter ?
              <div className="d-flex gap-2">
                <span><i className="bi bi-signpost h4"></i></span>
                <span className="">
                  Carburant fin
                  {" - "}
                  {reservation?.carburantEnded}%
                </span>
              </div>
            : null
          }

          <div className="d-flex gap-2 mt-1">
            <a href="https://www.usagers.antai.gouv.fr/demarches/saisienumero?lang=fr" style={{textDecoration: 'underline'}} target="_blank">Traiter une contravention</a>
          </div>
          {/* <div className="d-flex gap-2 mt-1">
            <a href={`${config?.PUBLIC_URL}/contravention/${reservation?._id}`}  style={{textDecoration: 'underline'}} target="_blank">Traiter une contravention</a>
          </div> */}
          <div className="d-flex gap-2 mt-2">
            <a href={reservation?.endContratUrl ? `https://dashboardapp.easygo.co/api/${reservation?.endContratUrl}` : `https://dashboardapp.easygo.co/api/${reservation?.startContratUrl}`} style={{textDecoration: 'underline'}} target="_blank">Contrat de location</a>
          </div>
        </div>
        <div className="flex-grow-1 d-flex flex-column gap-1">
          
        { reservation?.clientEmail && 
          <button
              style={{
                width: '260px'
              }}
              className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
              onClick={() => {
                setReservation({ ...reservation, clientEmail: reservation?.clientEmail, reservationNumber: reservation?.reservationNumber });
                handleOpenCloseReservationEdition(true)()
              }}
            >
          
          
              <span className="mr-2">
                <i className="bi bi-pencil-fill"></i>
              </span>
              Modifier Réservation externe
            </button>
            
            
            }
            <button
              style={{
                width: '260px'
              }}
              className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
              onClick={() => {
                setReservation({ ...reservation as Reservation, clientEmail: reservation?.clientEmail, reservationNumber: reservation?.reservationNumber as number });
                handleOpenCloseContraventionEdition(true)()
              }}
            >
              {/* <span className="mr-2">
                <i className="bi bi-file-plus"></i>
              </span> */}
              <span className="mr-2">
                <i className="bi bi-file-text-fill"></i>
              </span> Ajouter Contravention
            </button>

            
            {/* { reservation?.status == "CANCELED" ?  */}
                <button
                style={{
                  width: '260px'
                }}
                  className="btn btn-success rounded-3 d-flex gap-2 fw-bold text-uppercase"
                  onClick={() => {
                    setReservation({ ...reservation as Reservation, clientEmail: reservation?.clientEmail, reservationNumber: reservation?.reservationNumber as number });
                    handleOpenCloseRemboursementEdition(true)()
                  }}
                >
                  {/* <span className="mr-2">
                    <i className="bi bi-file-plus"></i>
                  </span> */}
                  <span className="mr-2">
                    <i className="bi bi-file-text-fill"></i>
                  </span> Remboursement
                </button>
                {/* : "" */}

            {/* } */}

            <button
              style={{
                width: '260px'
              }}
              className="btn btn-secondary rounded-3 d-flex gap-2 fw-bold text-uppercase"
              onClick={() => {
                // Redirection vers la page reservationHistory avec l'ID de la réservation
                window.location.href = `/reservationHistory/${reservationId}`;
              }}
            >
            <span className="mr-2">
            <i className="bi bi-clock-history"></i>
            </span>Historique
          </button>

          <button
              style={{
                width: '260px'
              }}
              className="btn btn-secondary rounded-3 d-flex gap-2 fw-bold text-uppercase"
              onClick={() => {
                // Redirection vers la page reservationHistory avec l'ID de la réservation
                window.location.href = `/dommages/${reservationId}`;
              }}
            >
            <span className="mr-2">
            <i className="bi bi-window"></i>
            </span>Dommages
          </button>

        </div>
      </div>
      {
        reservation?.user.driverLicencePhotosUrl!.length as number > 0 ?
          <>
            <h1 className="h5">Permis de conduire</h1>
            <div className="d-flex justify-content-between">
              <a
                href={reservation?.user.driverLicencePhotosUrl![0].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation?.user.driverLicencePhotosUrl![0].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
              <a
                href={reservation?.user.driverLicencePhotosUrl![1].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation?.user.driverLicencePhotosUrl![1].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
            </div>
          </>
        : null
      }

      { (reservation?.secondUser && reservation?.secondUser.driverLicencePhotosUrl!.length) && (
          <>
            <h1 className="h5">Permis de conduire secondaire</h1>
            <div className="d-flex justify-content-between">
              <a
                href={reservation?.secondUser.driverLicencePhotosUrl![0].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation?.secondUser.driverLicencePhotosUrl![0].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
              <a
                href={reservation?.secondUser.driverLicencePhotosUrl![1].photoUrl}
                target="_blank"
                className="border border-primary mb-1 border-bottom"
                style={{
                  width: "45%",
                  height: "200px",
                  overflow: 'hidden'
                }}
              >
                <img
                  src={reservation?.secondUser.driverLicencePhotosUrl![1].photoUrl}
                  alt="Permis de conduire"
                  style={{
                    objectFit: 'contain',
                    backgroundPosition: 'center',
                    backgroundSize: "contain",
                    width: "100%",
                    height: "100%",
                  }}
                />
              </a>
            </div>
          </>
        ) 
      }
      <>
        <h1 className="h5 mt-3 mb-3">{etats.length > 0 && ('Dommage & états des lieux')}</h1>
        <div>
          {
            etats?.map((etat) => {
              return(
                <>
                  <span className={`badge mb-2 mt-3 py-1 ${etaStatusClass(etat.type)}`}>
                    {EtatStatusLabel(etat.type)}
                  </span>
                  <p>{etat.description}</p>
                  <div className="d-flex justify-content-between">
                    {
                      etat.photos?.map((photo) => {
                        return(
                          <a
                            href={photo}
                            target="_blank"
                            className="border border-primary mb-1 border-bottom"
                            style={{
                              width: "33.333%",
                              height: "250px",
                              overflow: 'hidden'
                            }}
                          >
                            <img
                              src={photo}
                              alt="Permis de conduire"
                              style={{
                                objectFit: 'cover',
                                backgroundPosition: 'center',
                                backgroundSize: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                            />
                          </a>
                        )
                      })
                    }
                  </div>
                </>
              )
            })
          }
        </div>
      </>
      <CreateFormDialog
        handleClose={handleOpenCloseReservationEdition(false)}
        reservation={reservation as Reservation}
        show={showNewAndEditReservationModal}
       />
      <ContraventionDialog
        handleClose={handleOpenCloseContraventionEdition(false)}
        reservation={reservation as Reservation}
        show={showNewAndEditContraventionModal}
       />

      <RemboursementDialog
        handleClose={handleOpenCloseRemboursementEdition(false)}
        reservation={reservation as Reservation}
        show={showNewAndEditRemboursementModal}
       />
    </div>
    </div>
  )
};

export default ReservationOne;